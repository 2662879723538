import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, CheckboxOptionType, DatePicker, DatePickerProps, Select, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { LURLReportAction } from '../../store/types/lurl';
import { RootState } from '../../store/reducers';
import { formatYearMonthDay } from '../../helpers/date';
import { FetchLURLReportRequest, LURLReason, LURLStat } from '../../models/lurl';
import { Country } from '../../models/country';
import CountrySelect from '../common/CountrySelect';
import TDMSelect from '../common/TDMSelect';
import BundleSelect from '../common/BundleSelect';
import ProviderSelect from '../common/ProviderSelect';
import { ProviderListItem } from '../../models/provider';
import LURLReasonSelect from '../common/LURLReasonSelect';
import XLSDownload from '../common/XLSXDownload';
import ThunkArguments from '../../store/types/common';
import ZoneSelect from '../common/ZoneSelect';
import UserCategorySelect from '../common/UserCategorySelect';
import UserIPSelect from '../common/UserIPSelect';
import { KindApp } from '../../models/bundle';
import KindAppSelect from '../common/KindAppSelect';

const { Text } = Typography;
const { Option } = Select;

interface LURLReportBarProps {
    fetchLurlReport: (req: FetchLURLReportRequest)
        => (dispatch: Dispatch<LURLReportAction>,
            getState: () => RootState, args: ThunkArguments) => Promise<void>;
    lurlStats: LURLStat[];
    lurlReasons: LURLReason[];
    providersList: ProviderListItem[];
    countries: Country[];
    groupByList: string[];
    groupByOptions: Record<string, string>;
    onGroupByChange: (checkedValues: CheckboxOptionType[]) => void;
}

const dateFormat = 'YYYY-MM-DD';

const LURLReportBar: React.FC<LURLReportBarProps> = props => {
    const { fetchLurlReport, lurlStats, lurlReasons, providersList, countries, groupByOptions,
        onGroupByChange, groupByList } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatYearMonthDay(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatYearMonthDay(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [checkedValues, setCheckedValues] = useState<CheckboxOptionType[]>([]);
    const [bundle, setBundle] = useState<string | undefined>(undefined);
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [zone, setZone] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [tdm, setTDM] = useState<string | undefined>(undefined);
    const [lurlReason, setLURLReason] = useState<string | undefined>(undefined);
    const [userCategory, setUserCategory] = useState<string | undefined>(undefined);
    const [kindApp, setKindApp] = useState<string | undefined>(KindApp['Huawei AdExchange']);
    const [userIP, setUserIP] = useState<string | undefined>(undefined);

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setFrom(dateString);
        }
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setTo(dateString);
        }
    };

    const bundleChange = (value: string) => setBundle(value);
    const tdmChange = (value: string) => setTDM(value);
    const userCategoryChange = (value: string) => setUserCategory(value);
    const kindAppChange = (value: string) => setKindApp(value);

    const search = () => {
        onGroupByChange(checkedValues);
        const req: FetchLURLReportRequest = {
            from,
            to,
            provider,
            bundle,
            country,
            zone,
            tdm,
            lurlReason,
            userCategory,
            kind_app: kindApp,
            user_ip: userIP,
            groupBy: checkedValues.map(item => item.toString()),
        };
        fetchLurlReport(req);
    };

    const renderGroupBy = () => (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '300px' }}
            onChange={setCheckedValues}
        >
            {[...Object.keys(groupByOptions)]
                .sort((a, b) => a.localeCompare(b))
                .map(key => <Option key={key} value={key}>{groupByOptions[key]}</Option>)}
        </Select>
    );

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16, whiteSpace: 'nowrap' }}
            >
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>From: </Text>
                    <DatePicker onChange={onFromChange} defaultValue={dayjs(getDefaultFrom(), dateFormat)} />
                </Col>
                <Col>
                    <Text>To: </Text>
                    <DatePicker onChange={onToChange} defaultValue={dayjs(getDefaultTo(), dateFormat)} />
                </Col>
                <Col>
                    {providersList.length > 0 && <ProviderSelect providers={providersList} onChange={setProvider} />}
                </Col>
                <Col>
                    <Text>User category: </Text>
                    <UserCategorySelect value={userCategory} onChange={userCategoryChange} />
                </Col>
                <Col>
                    <Text>Contains user IP:</Text> <UserIPSelect value={userIP} onChange={setUserIP} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Bundle: </Text><BundleSelect onChange={bundleChange} />
                </Col>
                <Col>
                    <Text>API:</Text> <KindAppSelect value={kindApp} onChange={kindAppChange} />
                </Col>
                <Col>
                    <Text>Traffic distribute mode: </Text><TDMSelect value={tdm} onChange={tdmChange} />
                </Col>
                <Col>
                    <CountrySelect countries={countries} onChange={setCountry} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Zone:</Text> <ZoneSelect value={zone} onChange={setZone} />
                </Col>
                <Col flex="auto">
                    {lurlReasons.length > 0 && <LURLReasonSelect reasons={lurlReasons} onChange={setLURLReason} />}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Breakdowns: </Text>{renderGroupBy()}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
                <Col>
                    {lurlStats.length > 0 && (
                        <XLSDownload
                            data={lurlStats}
                            filename="performance_report.xlsx"
                            breakdownOptions={Object.keys(groupByOptions)}
                            breakdowns={groupByList}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default LURLReportBar;
