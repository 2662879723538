import { Layout, Typography, Space } from 'antd';
import styles from './PageNotFound.module.scss';

const { Content } = Layout;
const { Title, Text } = Typography;

const PageNotFound: React.FC = () => (
    <Layout>
        <Content>
            <Title>Uh-oh...</Title>
            <Space direction="vertical">
                <Text className={styles.text}>
                    The page you are looking for may have been moved, deleted, or possibly never existed.
                </Text>
                <Text className={styles['error-code']}>404</Text>
            </Space>
        </Content>
    </Layout>
);

export default PageNotFound;

export {};
