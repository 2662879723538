import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName } from '../../models/country';
import { ProviderListItem, ProviderName } from '../../models/provider';
import { isAdmin, isDeviceWriter, UserGroups } from '../../models/user';
import { DeleteAction, UpdateAction } from '../../store/types/actions';
import { compareDates, formatDateTime } from '../../helpers/date';
import DevicesActions from './DevicesActions';
import Device from '../../models/device';

interface weightMapPerDeviceColumn {
    id?: number;
    device_id: string;
    country: string;
    device_user: string;
    updated_at: string;
    updated_by: string;
}

interface DevicesTableProps {
    loading: boolean;
    error: null | string;
    devices: Device[];
    countries: Country[];
    providersList: ProviderListItem[];
    groups: UserGroups[];
    updateDevice: UpdateAction<Device, void>;
    deleteDevice: DeleteAction;
}

const DevicesTable: React.FC<DevicesTableProps> = props => {
    const { loading, error, devices, countries, providersList, groups, updateDevice, deleteDevice } = props;

    const getWeightMap = (): weightMapPerDeviceColumn[] => (
        devices.map(device => {
            const providerWeights = device.weights;
            const { id, device_id, device_user, updated_by, country } = device;
            return {
                id,
                device_id,
                country: getCountryFullName(country),
                device_user,
                updated_at: device.updated_at || '',
                updated_by,
                ...providerWeights,
            };
        })
    );

    const getProvidersColumns = (): ColumnsType<weightMapPerDeviceColumn> => (
        providersList.filter(provider => provider.provider_name !== 'unknown' as ProviderName)
            .map(({ provider_name: providerName, display_name: displayName }) => ({
                title: displayName,
                dataIndex: providerName,
                key: providerName,
                align: 'right',
                render: percent => percent ? `${percent}%` : '',
            }
            ))
    );

    const isAllowedUser = (): boolean => isAdmin(groups) ? true : isDeviceWriter(groups);

    const renderActions = (record: weightMapPerDeviceColumn) => {
        const device = devices.find(w => w.id === record.id);
        return isAllowedUser() && device
            ? (
                <DevicesActions
                    device={device}
                    error={error}
                    countries={countries}
                    providersList={providersList}
                    updateDevice={updateDevice}
                    deleteDevice={deleteDevice}
                />
            ) : null;
    };

    const columns: ColumnsType<weightMapPerDeviceColumn> = [
        {
            title: 'GAID/OAID',
            dataIndex: 'device_id',
            key: 'device_id',
            width: '10%',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.device_id.localeCompare(b.device_id),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Country Name',
            dataIndex: 'country',
            key: 'country',
            width: '7%',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.country.localeCompare(b.country),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'User',
            dataIndex: 'device_user',
            key: 'device_user',
            width: '5%',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.device_user.localeCompare(b.device_user),
        },
        ...getProvidersColumns(),
        {
            title: 'Updated at',
            dataIndex: 'updated_at',
            key: 'updatedAt',
            align: 'right',
            width: '10%',
            render: updatedAt => <span>{formatDateTime(updatedAt)}</span>,
            sorter: (a, b) => compareDates(a.updated_at || '', b.updated_at || ''),
        },
        {
            title: 'Updated by',
            dataIndex: 'updated_by',
            key: 'updatedBy',
            align: 'right',
            width: '10%',
            sorter: (a, b) => a.updated_by.localeCompare(b.updated_by),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '4%',
            render: (record: weightMapPerDeviceColumn) => renderActions(record),
        },
    ];

    const getScrollX = () => 150 * providersList.length;

    return (
        <Table
            className="devices__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.country}
            dataSource={getWeightMap()}
            columns={columns}
            size="small"
            pagination={false}
            scroll={{ x: getScrollX() }}
            tableLayout="auto"
        />
    );
};

export default DevicesTable;
