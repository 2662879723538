import { useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { Typography, Table, Input, Space, Button, Col, Row, InputRef } from 'antd';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import { RootState } from '../../store/reducers';
import * as BundleActionCreators from '../../store/actions/bundleAction';
import * as DefaultPriceActionCreators from '../../store/actions/defaultPriceAction';
import * as CountryActionCreators from '../../store/actions/countryAction';
import BundlesBar from './BundlesBar';
import BundlesActions from './BundlesActions';
import Bundle from '../../models/bundle';
import CountryPrices from './CountryPrices';
import { isAdmin, isBundleWriter } from '../../models/user';
import DefaultPriceComponent from './DefaultPrice';
import { errorNotify } from '../common/Notify';
import { compareDates, formatDateTime } from '../../helpers/date';
import { appGalleryURL, bundlesWithNoHagID, hardcodedHagID } from '../forms/BundleForm';

const { Title, Text } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    bundleState: state.bundle,
    defaultPriceState: state.defaultPrice,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...BundleActionCreators,
        ...DefaultPriceActionCreators,
        ...CountryActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type BundlesProps = StateProps & DispatchProps;

const Bundles: React.FC<BundlesProps> = props => {
    const { userState, bundleState, defaultPriceState, countryState } = props;
    const { groups } = userState;
    const { bundles, error: errorBundle, loading: loadingBundle } = bundleState;
    const { countries, error: errorCountry } = countryState;
    const { defaultPrice, error: errorDefaultPrice, loading: loadingDefaultPrice } = defaultPriceState;
    const { fetchBundles, addBundle, updateBundle, deleteBundle, fetchDefaultPrice, fetchCountries } = props;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    let searchInput: InputRef | null;
    // TODO maybe use local storage to store this variable?
    const defaultPageSize = 20;

    const handleSearch = (selectedKeys: React.Key[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0] ? selectedKeys[0].toString() : '');
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps: (dataIndex: string) => ColumnType<Bundle> = dataIndex => ({
        // TODO: enable react/no-unstable-nested-components and move these functions out of here
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters!)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0] ? selectedKeys[0].toString() : '');
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownOpenChange: open => {
            if (open) {
                setTimeout(() => searchInput!.select(), 100);
            }
        },
        render: (text: string) => searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text || ''}
            />
        ) : (
            text
        ),
    });

    const showTotal = (total: number) => <Text>Total sum of bundles: {total}</Text>;

    const rowExpandable = (record: Bundle) => {
        if (!countries.length) return false;
        return record.price_by_country ? record.price_by_country.length > 0 : false;
    };

    const expandedRowRender = (record: Bundle) => (
        <Row justify="center">
            <Col span={12}>
                <CountryPrices
                    data={record.price_by_country!}
                    countriesList={countries}
                />
            </Col>
        </Row>
    );

    const isAllowedUser = (): boolean => {
        if (isAdmin(groups)) return true;
        return isBundleWriter(groups);
    };

    const columns: ColumnsType<Bundle> = [
        {
            title: 'Bundle Name',
            dataIndex: 'name',
            key: 'bundleName',
            width: '20%',
            onFilter: (value, record) => record.name
                ? record.name.toLowerCase().includes(value.toString().toLowerCase())
                : false,
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'HAG/GP URLs',
            dataIndex: 'url',
            key: 'url',
            width: '20%',
            render: (hagURL, { name, gp_url: gpURL }) => {
                const url = bundlesWithNoHagID.includes(name) ? appGalleryURL + hardcodedHagID : hagURL;
                return (
                    <div>
                        {url ? <div><a href={url} target="_blank" rel="noreferrer">{url}</a></div> : null}
                        {gpURL ? <div><a href={gpURL} target="_blank" rel="noreferrer">{gpURL}</a></div> : null}
                    </div>
                );
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'priceForApp',
            align: 'right',
            width: '10%',
            sorter: (a, b) => a.price! - b.price!,
        },
        {
            title: 'Interstitial price',
            dataIndex: 'price_19',
            key: 'price19',
            align: 'right',
            width: '10%',
            sorter: (a, b) => a.price_19! - b.price_19!,
        },
        {
            title: 'Last updated',
            dataIndex: 'updated_at',
            key: 'updatedAt',
            align: 'right',
            width: '10%',
            render: updatedAt => <span>{formatDateTime(updatedAt)}</span>,
            sorter: (a, b) => compareDates(a.updated_at || '', b.updated_at || ''),
        },
        {
            title: 'Updated by',
            dataIndex: 'updated_by',
            key: 'updatedBy',
            align: 'right',
            width: '10%',
            sorter: (a, b) => a.updated_by.localeCompare(b.updated_by),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '10%',
            render: record => isAllowedUser()
                ? (
                    <BundlesActions
                        bundle={record}
                        defaultPrice={defaultPrice}
                        countries={countries}
                        error={errorBundle}
                        deleteBundle={deleteBundle}
                        updateBundle={updateBundle}
                    />
                ) : null,
        },
    ];

    useEffect(() => {
        fetchBundles();
        fetchDefaultPrice();
        fetchCountries();
    }, []);

    useEffect(() => {
        if (errorDefaultPrice) errorNotify('Default price error', errorDefaultPrice);
    }, [errorDefaultPrice]);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    return (
        <>
            <Title level={2} className="title">Third Party Apps</Title>
            {bundles.length > 0
                && (
                    <BundlesBar
                        bundles={bundles}
                        defaultPrice={defaultPrice}
                        countries={countries}
                        error={errorBundle}
                        groups={groups}
                        addBundle={addBundle}
                    />
                )}
            <Table
                loading={loadingBundle}
                rowKey={record => record.id!}
                dataSource={bundles}
                columns={columns}
                pagination={{ defaultPageSize, showTotal }}
                expandable={{
                    expandedRowRender,
                    rowExpandable,
                }}
            />
            {!errorDefaultPrice && (
                <DefaultPriceComponent
                    defaultPrice={defaultPrice}
                    loadingDefaultPrice={loadingDefaultPrice}
                />
            )}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bundles);
