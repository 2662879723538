import { useState } from 'react';
import { Modal, Form, Button } from 'antd';
import { ProviderListItem } from '../../models/provider';
import { Country, getCountryFullName } from '../../models/country';
import { UpdateAction } from '../../store/types/actions';
import { defaultNotify } from '../common/Notify';
import Device from '../../models/device';
import DeviceUpdateForm from '../forms/DeviceUpdateForm';

interface EditDeviceModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    device: Device;
    countries: Country[];
    error: null | string;
    providersList: ProviderListItem[];
    updateDevice: UpdateAction<Device, void>;
}

const EditDeviceModal: React.FC<EditDeviceModalProps> = props => {
    const { open, setOpen, error, device, providersList, updateDevice } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const getSupportingProviders = ():
        ProviderListItem[] => providersList.filter(provider => provider.allowed_countries.includes(device.country));

    const handleOk = async (values: Record<string, any>) => {
        const { id, device_id: deviceID, device_user, country, updated_by } = device;
        const { weights } = values;
        const updatedDevice: Device = {
            id,
            device_id: deviceID,
            device_user,
            country,
            weights,
            updated_by,
        };
        setConfirmLoading(true);
        await updateDevice(updatedDevice);
        setConfirmLoading(false);
        if (!error) {
            setOpen(false);
            defaultNotify(`${deviceID} updated`, '');
        }
    };

    const handleCancel = () => setOpen(false);

    const getTitle = (id: string, country: string):
        string => `Edit ${id} device for ${getCountryFullName(country)} country`;

    return (
        <Modal
            title={getTitle(device.device_id, device.country)}
            getContainer={false}
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    form="deviceUpdateForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    loading={confirmLoading}
                >
                    Submit
                </Button>,
            ]}
        >
            <DeviceUpdateForm
                form={form}
                device={device}
                providersList={getSupportingProviders()}
                onFinish={handleOk}
            />
        </Modal>
    );
};

export default EditDeviceModal;
