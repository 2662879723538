import { useState } from 'react';
import { Button, CheckboxOptionType, DatePicker, DatePickerProps, Typography, Row, Col, Select, Input } from 'antd';
import dayjs from 'dayjs';
import { formatYearMonthDay } from '../../helpers/date';
import { Action } from '../../store/types/actions';
import { LangRatioReportAction } from '../../store/types/langRatioReport';
import { FetchLangRatioReportRequest } from '../../models/langRatioReport';
import { Country } from '../../models/country';
import CountrySelect from '../common/CountrySelect';
import { ProviderListItem } from '../../models/provider';
import ProviderSelect from '../common/ProviderSelect';

const { Text } = Typography;
const { Option } = Select;

interface LanguageRatioReportBarProps {
    countriesList: Country[];
    providersList: ProviderListItem[];
    groupByList: string[];
    groupByOptions: Record<string, string>;
    onGroupByChange: (checkedValues: CheckboxOptionType[]) => void;
    fetchLangRatioReport: (req: FetchLangRatioReportRequest) => Action<LangRatioReportAction>;
}

const dateFormat = 'YYYY-MM-DD';

const LanguageRatioReportBar: React.FC<LanguageRatioReportBarProps> = props => {
    const { countriesList, providersList, groupByOptions, onGroupByChange, fetchLangRatioReport } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatYearMonthDay(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatYearMonthDay(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [reqLang, setReqLang] = useState<string | undefined>(undefined);
    const [respLang, setRespLang] = useState<string | undefined>(undefined);
    const [respDetectedLang, setRespDetectedLang] = useState<string | undefined>(undefined);
    const [checkedValues, setCheckedValues] = useState<CheckboxOptionType[]>([]);

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setFrom(dateString);
        }
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setTo(dateString);
        }
    };

    const search = () => {
        const groupBy = checkedValues;
        onGroupByChange([...new Set(checkedValues)]);

        const req: FetchLangRatioReportRequest = {
            from,
            to,
            title,
            provider,
            country,
            rq_language: reqLang,
            rp_language: respLang,
            rp_detected_language: respDetectedLang,
            groupBy: groupBy.map(item => item.toString()),
        };

        fetchLangRatioReport(req);
    };

    const renderGroupBy = () => (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '300px' }}
            onChange={setCheckedValues}
        >
            {[...Object.keys(groupByOptions)]
                .map(key => <Option key={key} value={key}>{groupByOptions[key]}</Option>)}
        </Select>
    );

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>From: </Text>
                    <DatePicker
                        onChange={onFromChange}
                        defaultValue={dayjs(getDefaultFrom(), dateFormat)}
                    />
                </Col>
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>To: </Text>
                    <DatePicker
                        onChange={onToChange}
                        defaultValue={dayjs(getDefaultTo(), dateFormat)}
                    />
                </Col>
                <Col>
                    {providersList.length && <ProviderSelect providers={providersList} onChange={setProvider} />}
                </Col>
                <Col>
                    <CountrySelect countries={countriesList} onChange={setCountry} />
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                    <Text style={{ whiteSpace: 'nowrap', marginRight: 4 }}>Title:</Text>
                    <Input type="text" value={title} onChange={e => setTitle(e.target.value)} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
                    <Text style={{ whiteSpace: 'nowrap', marginRight: 4 }}>Request lang:</Text>
                    <Input type="text" value={reqLang} onChange={e => setReqLang(e.target.value)} />
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
                    <Text style={{ whiteSpace: 'nowrap', marginRight: 4 }}>Response lang:</Text>
                    <Input type="text" value={respLang} onChange={e => setRespLang(e.target.value)} />
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
                    <Text style={{ whiteSpace: 'nowrap', marginRight: 4 }}>Response detected lang:</Text>
                    <Input type="text" value={respDetectedLang} onChange={e => setRespDetectedLang(e.target.value)} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col><Text>Breakdowns:</Text> {renderGroupBy()}</Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
            </Row>
        </>
    );
};

export default LanguageRatioReportBar;
