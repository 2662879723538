import { useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Space } from 'antd';
import { RootState } from '../../store/reducers';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountryActionCreators from '../../store/actions/countryAction';
import ProviderBar from './ProviderBar';
import { errorNotify } from '../common/Notify';
import ProviderTable from './ProviderTable';
import { PostData, ProviderName } from '../../models/provider';
import { Country } from '../../models/country';
import { formatDateTime } from '../../helpers/date';
import { capitalize } from '../../helpers/string';
import { getProviderDisplayName } from '../../helpers/provider';

const { Title, Paragraph } = Typography;

const mapStateToProps = (state: RootState) => ({
    providerState: state.provider,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountryActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps & {
    providerName: ProviderName;
};

const Provider: React.FC<ProviderProps> = props => {
    const [currentPostData, setCurrentPostData] = useState<PostData[]>();
    const [currentLastUpdate, setCurrentLastUpdate] = useState<string>();

    const { countryState, providerState, providerName } = props;
    const { countries, error: errorCountry } = countryState;
    const { providers, error: errorProvider, loading: loadingProvider } = providerState;
    const { fetchProvider, fetchCountries } = props;

    const getCountries = (): Country[] => {
        if (providerName === 'nativex') {
            const providerCountries = providers.map(item => item.country);
            return countries.filter(country => providerCountries.includes(country.short_name));
        }
        return countries;
    };

    const getDisabledCountries = (): Country[] => {
        const providerCountries = providers.map(item => item.country);
        return countries.filter(country => !providerCountries.includes(country.short_name));
    };

    const selectCountry = (value: string) => {
        const filteredProviders = providers.find(item => item.country === value);
        setCurrentLastUpdate(filteredProviders?.updated_at);
        setCurrentPostData(filteredProviders?.posts);
    };

    useEffect(() => {
        fetchProvider(providerName);
        fetchCountries();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    return (
        <>
            <Title level={2} className="title">
                {capitalize(getProviderDisplayName(providerName))} Articles
            </Title>
            {providers?.length > 0
                ? (
                    <>
                        <ProviderBar
                            countries={getCountries()}
                            disabledCountries={getDisabledCountries()}
                            selectCountry={selectCountry}
                        />
                        {currentPostData && (
                            <Space style={{ display: 'block', textAlign: 'left' }}>
                                <Paragraph>Last Update: {formatDateTime(currentLastUpdate || '')}</Paragraph>
                                <Paragraph>Total amount: {currentPostData.length}</Paragraph>
                            </Space>
                        )}
                        <ProviderTable loading={loadingProvider} posts={currentPostData} />
                    </>
                )
                : <Title level={2}>No active posts</Title>}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Provider);
