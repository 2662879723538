import { useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import BundleForm from '../forms/BundleForm';
import Bundle from '../../models/bundle';
import formType from '../../types/form';
import { CountryPrice, DefaultPrice } from '../../models/price';
import { Country } from '../../models/country';
import { CreateAction } from '../../store/types/actions';
import { defaultNotify, errorNotify } from '../common/Notify';

const { useForm } = Form;

interface AddBundleProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    bundlesNamesList: string[];
    defaultPrice: DefaultPrice;
    countries: Country[];
    error: null | string;
    addBundle: CreateAction<Bundle, void>;
}

const AddBundle: React.FC<AddBundleProps> = props => {
    const { open, setOpen, bundlesNamesList, defaultPrice, countries, error, addBundle } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = useForm<Bundle>();

    const getPricesByCountryFromForm = (input: any): CountryPrice[] => {
        if (!input) {
            return [];
        }

        const typed: CountryPrice[] = input.map((countryPrice: any) => ({
            country: countryPrice.country,
            price: +countryPrice.price,
            price_19: +countryPrice.price_19,
        }
        ));
        const countryPricesMap:
            { [key: string]: { price: number, price_19: number } } = typed.reduce((prev, current) => {
                const { country, price, price_19 } = current;
                return { ...prev, [country]: { price, price_19 } };
            }, {});
        return Object
            .entries(countryPricesMap).map(([country, { price, price_19 }]) => ({ country, price, price_19 }));
    };

    const handleSubmit = async () => {
        const values = form.getFieldsValue();
        const {
            name,
            url,
            gp_url: gpURL,
            price,
            price_19: price19,
            price_by_country: priceByCountry,
        } = values;
        const pricesByCountry = getPricesByCountryFromForm(priceByCountry);
        const newBundle: Bundle = {
            name,
            url,
            gp_url: gpURL,
            price: +price,
            price_19: +price19,
            price_by_country: pricesByCountry,
            // TODO pass email here
            updated_by: '',
        };
        setConfirmLoading(true);
        try {
            await addBundle(newBundle);
            defaultNotify(`${name} bundle added`, '');
            setOpen(false);
        } catch (e: any) {
            errorNotify('Error adding bundle', e.message);
        } finally {
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        form.setFieldsValue({ price_by_country: [] });
        setOpen(false);
    };

    return (
        <Modal
            title="Add Bundle"
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            getContainer={false}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button onClick={handleSubmit} key="submit" htmlType="submit" type="primary" loading={confirmLoading}>

                    Submit
                </Button>,
            ]}
        >
            <BundleForm
                form={form}
                defaultPrice={defaultPrice}
                bundlesNamesList={bundlesNamesList}
                countries={countries}
                type={formType.ADD_FORM}
            />
        </Modal>
    );
};

export default AddBundle;
