import { AxiosError } from 'axios';
import queryString from 'query-string';
import {
    AdblockAggregatedStats,
    AdblockReportStats,
    AdblockStats,
    FetchAdblockReportRequest,
    MostBlockedStats,
} from '../../models/adblock';
import { authInterceptor } from './interceptors';
import { AdblockAction, AdblockActionTypes } from '../types/adblock';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

export const fetchAdblockAggregatedReport = (from: string, to: string, groupBy: string[]): Action<AdblockAction> => {
    const query = queryString.stringify({ from, to, group_by: groupBy }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<AdblockAggregatedStats>(`reports/adblock/aggregated?${query}`);
            dispatch({ type: AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_ERROR,
                'Error fetching adblock aggregated report',
            );
        }
    };
};

export const fetchAdblockStatsReport = (from: string, to: string, crID: string, groupBy: string[]): Action<AdblockAction> => {
    const query = queryString.stringify({ from, to, cr_id: crID, group_by: groupBy }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: AdblockActionTypes.FETCH_ADBLOCK_STATS_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<AdblockStats>(`reports/adblock?${query}`);
            dispatch({ type: AdblockActionTypes.FETCH_ADBLOCK_STATS_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                AdblockActionTypes.FETCH_ADBLOCK_STATS_ERROR,
                'Error fetching adblock stats report',
            );
        }
    };
};

export const fetchMostBlockedReport = (from: string, to: string): Action<AdblockAction> => {
    const query = queryString.stringify({ from, to }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: AdblockActionTypes.FETCH_MOST_BLOCKED_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<MostBlockedStats>(`reports/adblock/most-blocked?${query}`);
            dispatch({ type: AdblockActionTypes.FETCH_MOST_BLOCKED_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, AdblockActionTypes.FETCH_MOST_BLOCKED_ERROR, 'Error fetching most blocked report');
        }
    };
};

export const unblockCrID = (crID: string): Action<AdblockAction> => async (dispatch, getState, { defaultAPI }) => {
    dispatch({ type: AdblockActionTypes.UNBLOCK_CRID_REQUEST });

    defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

    return new Promise((resolve, reject) => {
        defaultAPI.delete(`adblock/${crID}`)
            .then(() => {
                dispatch({ type: AdblockActionTypes.UNBLOCK_CRID_SUCCESS, payload: crID });
                resolve();
            })
            .catch(e => {
                handleError(dispatch, e, AdblockActionTypes.UNBLOCK_CRID_ERROR, 'Error unblocking most blocked');
                reject(e.message);
            });
    });
};

export const fetchAdblockReport = (req: FetchAdblockReportRequest): Action<AdblockAction> => {
    const { from, to, country, provider, zone } = req;
    const query = queryString.stringify({ from, to, country, provider, zone }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: AdblockActionTypes.FETCH_ADBLOCK_REPORT_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<AdblockReportStats>(`reports/adblock/adblock-report?${query}`);
            dispatch({ type: AdblockActionTypes.FETCH_ADBLOCK_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                AdblockActionTypes.FETCH_ADBLOCK_REPORT_ERROR,
                'Error fetching adblock report',
            );
        }
    };
};
