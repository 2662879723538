import { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import type { MenuProps } from 'antd';
import MenuProfile from './auth/MenuProfile';
import Bundles from './bundles/Bundles';
import Provider from './provider/Provider';
import WorkRequest from './work-request/WorkRequest';
import styles from './Page.module.scss';
import WeightMap from './weightmap/WeightMap';
import LURLReport from './lurl-report/LURLReport';
import IncomingReport from './incoming-report/IncomingReport';
import { WeightKind } from '../models/country';
import ErrorReport from './error-report/ErrorReport';
import PerformanceReport from './performance-report/PerformanceReport';
import FactWeightMap from './weightmap/FactWeightMap';
import BidFloorReport from './bidding-report/BidFloorReport';
import AdblockReport from './adblock-report/AdblockReport';
import WeightMapPerBundle from './weightmap/WeightMapPerBundle';
import LanguageRatioReport from './language-ratio-report/LangRatioReport';
import { Context } from '../Context';
import Devices from './qa-devices/Devices';
import PageNotFound from './PageNotFound/PageNotFound';

const { Header, Content } = Layout;

interface navItem {
    key: string;
    label: JSX.Element;
}

const Page: React.FC = () => {
    const [selectedKeys, setSelectedKeys] = useState([window.location.pathname]);
    const onSelectHandler = (e: SelectInfo) => setSelectedKeys(e.keyPath);
    const { isTechteam } = useContext(Context);

    const getNavItem = (key: string, label: string): navItem => (
        {
            key,
            label: <NavLink to={key}>{label}</NavLink>,
        }
    );

    const getSubMenu = (key: string, label: string, ...children: navItem[]) => (
        {
            key,
            label,
            children,
        }
    );

    const items: MenuProps['items'] = [
        getNavItem('/error-report', 'Error Report'),
        getSubMenu(
            'weight-map-sub-menu',
            'Weights',
            getNavItem('/weightmap', 'Weights Revshare'),
            getNavItem('/weightmap-bidding', 'Weights Bidding'),
            getNavItem('/weightmap-bidding-internal', 'Weights Bidding Internal'),
            getNavItem('/weightmap-xiaomi', 'Weights Xiaomi'),
            getNavItem('/weightmap-transsion', 'Weights Transsion'),
            getNavItem('/weightmap-vivo', 'Weights Vivo'),
            getNavItem('/weightmap-oppo', 'Weights Oppo'),
            getNavItem('/fact-weightmap', 'Fact Weights'),
            getNavItem('/bundle-weights', 'Weights For Bidding Bundles'),
        ),
        getNavItem('/bundles', 'Bundles'),
        // TODO someone deleted this backend part
        getSubMenu(
            'provider-sub-menu',
            'Providers',
            // getNavItem('/affiliates', 'Affiliates'),
            getNavItem('/nativesquid', 'NativeSquid'),
        ),
        getNavItem('/lurl-report', 'LURL Report'),
        getNavItem('/adblock-report', 'Adblock Report'),
        getNavItem('/incoming-report', 'Incoming Report'),
        getNavItem('/performance-report', 'Performance Report'),
        getNavItem('/bidfloor-report', 'BidFloor Report'),
        isTechteam ? getNavItem('/language-ratio-report', 'Language Ratio Report') : null,
        getNavItem('/devices', 'QA Devices'),
        getNavItem('/work-request', 'Work Request'),
    ];

    // TODO maybe I should wrap Router in App component or here return WithRouter(Page)
    return (
        <Router>
            <Layout className="layout">
                <Header className={styles.header}>
                    <Menu
                        className={styles.menu}
                        theme="dark"
                        mode="horizontal"
                        selectedKeys={selectedKeys}
                        onSelect={onSelectHandler}
                        items={items}
                    />
                    <MenuProfile />
                </Header>
                <Content style={{ padding: '0 50px' }}>
                    <div className={styles['site-layout-content']}>
                        <Routes>
                            <Route
                                path="/weightmap"
                                element={<WeightMap kind={WeightKind.RevShare} />}
                            />
                            <Route
                                path="/weightmap-bidding"
                                element={<WeightMap kind={WeightKind.Bidding} />}
                            />
                            <Route
                                path="/weightmap-bidding-internal"
                                element={<WeightMap kind={WeightKind.BiddingInternal} />}
                            />
                            <Route
                                path="/weightmap-xiaomi"
                                element={<WeightMap kind={WeightKind.Xiaomi} />}
                            />
                            <Route
                                path="/weightmap-transsion"
                                element={<WeightMap kind={WeightKind.Transsion} />}
                            />
                            <Route
                                path="/weightmap-vivo"
                                element={<WeightMap kind={WeightKind.Vivo} />}
                            />
                            <Route
                                path="/weightmap-oppo"
                                element={<WeightMap kind={WeightKind.Oppo} />}
                            />
                            <Route
                                path="/fact-weightmap"
                                element={<FactWeightMap />}
                            />
                            <Route path="/bundles" element={<Bundles />} />
                            {/* <Route
                                path="/affiliates"
                                element={<Provider providerName="affiliate" />}
                            /> */}
                            <Route
                                path="/nativesquid"
                                element={<Provider providerName="nativex" />}
                            />
                            <Route path="/lurl-report" element={<LURLReport />} />
                            <Route path="/incoming-report" element={<IncomingReport />} />
                            <Route path="/error-report" element={<ErrorReport />} />
                            <Route path="/adblock-report" element={<AdblockReport />} />
                            <Route path="/performance-report" element={<PerformanceReport />} />
                            <Route path="/bidfloor-report" element={<BidFloorReport />} />
                            <Route path="/work-request" element={<WorkRequest />} />
                            <Route path="/bundle-weights" element={<WeightMapPerBundle />} />
                            <Route path="/devices" element={<Devices />} />
                            {isTechteam
                                ? <Route path="/language-ratio-report" element={<LanguageRatioReport />} />
                                : null}
                            <Route path="/" element={<Navigate to="/error-report" replace />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </div>
                </Content>
                {/* <PageFooter /> */}
            </Layout>
        </Router>
    );
};

export default Page;
