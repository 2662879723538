import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckboxOptionType, Typography } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import * as LangRatioReportActionCreators from '../../store/actions/langRatioReportAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import LanguageRatioReportBar from './LangRatioReportBar';
import LanguageRatioReportTable from './LangRatioReportTable';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    providerState: state.provider,
    countryState: state.country,
    langRatioReportState: state.langRatioReport,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
        ...LangRatioReportActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type LanguageRatioReportProps = StateProps & DispatchProps;

const LanguageRatioReport: React.FC<LanguageRatioReportProps> = props => {
    const { countryState, providerState, langRatioReportState } = props;
    const { countries, error: errorCountry } = countryState;
    const { providersList, error: errorProvider } = providerState;
    const { langRatioReportStats, error: errorLangRatioReport, loading } = langRatioReportState;
    const { fetchCountries, fetchProvidersList, fetchLangRatioReport } = props;

    const groupByOptions: Record<string, string> = {
        rp_title: 'Title',
        provider: 'Provider',
        country: 'Country',
        rq_language: 'Request language',
        rq_wlang: 'Request Wlanguage',
        rp_language: 'Response language',
        rp_detected_language: 'Response detected language',
    };

    const [groupByList, setGroupByList] = useState<string[]>([]);
    const onGroupByChange = (checkedValues: CheckboxOptionType[]) => {
        setGroupByList(checkedValues.map(item => item.toString()));
    };

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorLangRatioReport) errorNotify('Language Ratio Report error', errorLangRatioReport);
    }, [errorLangRatioReport]);

    return (
        <>
            <Title level={2} className="title">Language Ratio Report</Title>
            <LanguageRatioReportBar
                countriesList={countries}
                providersList={providersList}
                groupByList={groupByList}
                groupByOptions={groupByOptions}
                onGroupByChange={onGroupByChange}
                fetchLangRatioReport={fetchLangRatioReport}
            />
            <LanguageRatioReportTable
                loading={loading}
                error={errorLangRatioReport}
                langRatioReportStats={langRatioReportStats}
                groupByList={groupByList}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageRatioReport);
