import { LangRatioReportAction, LangRatioReportActionTypes, LangRatioReportState } from '../types/langRatioReport';

const defaultState: LangRatioReportState = {
    langRatioReportStats: [],
    loading: false,
    error: null,
};

const langRatioReportReducer = (state = defaultState, action: LangRatioReportAction): LangRatioReportState => {
    switch (action.type) {
    case LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_SUCCESS:
        return { ...state, langRatioReportStats: action.payload, loading: false, error: null };
    case LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default langRatioReportReducer;
