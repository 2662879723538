enum TemplateType {
    Native = 'Native',
    Interstitial = 'Interstitial',
}

export const TemplateTypeMap: Record<string, string> = {
    native: TemplateType.Native,
    interstitial: TemplateType.Interstitial,
};

export type {
    TemplateType,
};
