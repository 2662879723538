import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, DatePicker, DatePickerProps, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { RootState } from '../../store/reducers';
import { formatYearMonthDay } from '../../helpers/date';
import CountrySelect from '../common/CountrySelect';
import { Country } from '../../models/country';
import { FetchBidFloorReportRequest } from '../../models/bidding';
import { BiddingReportAction } from '../../store/types/bidding';
import TDMSelect from '../common/TDMSelect';
import BundleSelect from '../common/BundleSelect';
import ThunkArguments from '../../store/types/common';

const { Text } = Typography;

interface BidFloorReportBar {
    fetchBidFloorReport: (req: FetchBidFloorReportRequest)
        => (dispatch: Dispatch<BiddingReportAction>, getState: () => RootState, args: ThunkArguments) => Promise<void>;
    countries: Country[];
}

const dateFormat = 'YYYY-MM-DD';

const BidFloorReportBar: React.FC<BidFloorReportBar> = props => {
    const { fetchBidFloorReport, countries } = props;

    const getDefaultDate = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatYearMonthDay(date.toString());
    };

    const [date, setDate] = useState(getDefaultDate());
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [bundle, setBundle] = useState<string | undefined>(undefined);
    const [tdm, setTDM] = useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onDateChange: DatePickerProps['onChange'] = (d, dateString) => {
        if (typeof dateString === 'string') {
            setDate(dateString);
        }
    };
    const tdmChange = (value: string) => setTDM(value);
    const bundleChange = (value: string) => setBundle(value);

    const search = () => {
        setShowErrorMessage(false);
        if (date === '') {
            setShowErrorMessage(true);
            setErrorMessage('Date should not be empty!');
            return;
        }
        const req: FetchBidFloorReportRequest = {
            date,
            country,
            bundle,
            traffic_distribute_mode: tdm,
        };
        fetchBidFloorReport(req);
    };

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '70%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Date: </Text>
                    <DatePicker onChange={onDateChange} defaultValue={dayjs(getDefaultDate(), dateFormat)} />
                </Col>
                <Col>
                    <CountrySelect countries={countries} onChange={setCountry} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '70%', margin: 'auto', marginBottom: 16 }}
            >
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>Traffic distribute mode: </Text><TDMSelect value={tdm} onChange={tdmChange} />
                </Col>
                <Col>
                    <Text>Bundle: </Text><BundleSelect onChange={bundleChange} />
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '70%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">
                        Search
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default BidFloorReportBar;
