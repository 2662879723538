import Device from '../../models/device';

export interface DeviceState {
    devices: Device[];
    loading: boolean;
    error: any;
}

export enum DeviceActionTypes {
    FETCH_DEVICES_REQUEST = 'FETCH_DEVICES_REQUEST',
    FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS',
    FETCH_DEVICES_ERROR = 'FETCH_DEVICES_ERROR',
    ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST',
    ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS',
    ADD_DEVICE_ERROR = 'ADD_DEVICE_ERROR',
    UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST',
    UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS',
    UPDATE_DEVICE_ERROR = 'UPDATE_DEVICE_ERROR',
    DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST',
    DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS',
    DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR',
}

interface FetchDevicesRequestAction {
    type: DeviceActionTypes.FETCH_DEVICES_REQUEST;
}

interface FetchDevicesSuccessAction {
    type: DeviceActionTypes.FETCH_DEVICES_SUCCESS;
    payload: Device[];
}

interface FetchDevicesErrorAction {
    type: DeviceActionTypes.FETCH_DEVICES_ERROR;
    payload: string;
}

interface AddDeviceRequestAction {
    type: DeviceActionTypes.ADD_DEVICE_REQUEST;
}

interface AddDeviceSuccessAction {
    type: DeviceActionTypes.ADD_DEVICE_SUCCESS;
    payload: Device;
}

interface AddDeviceErrorAction {
    type: DeviceActionTypes.ADD_DEVICE_ERROR;
    payload: string;
}

interface UpdateDeviceRequestAction {
    type: DeviceActionTypes.UPDATE_DEVICE_REQUEST;
}

interface UpdateDeviceSuccessAction {
    type: DeviceActionTypes.UPDATE_DEVICE_SUCCESS;
    payload: Device;
}

interface UpdateDeviceErrorAction {
    type: DeviceActionTypes.UPDATE_DEVICE_ERROR;
    payload: string;
}

interface DeleteDeviceRequestAction {
    type: DeviceActionTypes.DELETE_DEVICE_REQUEST;
}

interface DeleteDeviceSuccessAction {
    type: DeviceActionTypes.DELETE_DEVICE_SUCCESS;
    payload: string;
}

interface DeleteDeviceErrorAction {
    type: DeviceActionTypes.DELETE_DEVICE_ERROR;
    payload: string;
}

export type DeviceAction = FetchDevicesRequestAction |
    FetchDevicesSuccessAction |
    FetchDevicesErrorAction |
    AddDeviceRequestAction |
    AddDeviceSuccessAction |
    AddDeviceErrorAction |
    UpdateDeviceRequestAction |
    UpdateDeviceSuccessAction |
    UpdateDeviceErrorAction |
    DeleteDeviceRequestAction |
    DeleteDeviceSuccessAction |
    DeleteDeviceErrorAction;
