import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Typography, Row, Col } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import { RootState } from '../../store/reducers';
import { errorNotify, defaultNotify } from '../common/Notify';
import WeightMapTable from './WeightMapTable';
import { WeightKind } from '../../models/country';
import {
    isAdmin,
    isBiddingInternalWriter,
    isBiddingWriter,
    isOppoWriter,
    isRevShareWriter,
    isTranssionWriter,
    isVivoWriter,
    isXiaomiWriter,
} from '../../models/user';
import AddCountry from '../modals/AddCountry';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    providerState: state.provider,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps & {
    kind: WeightKind;
};

const WeightMap: React.FC<ProviderProps> = props => {
    const { userState, providerState, countryState, kind } = props;
    const { groups } = userState;
    const { providersList, error: errorProvider, loading: loadingProvider } = providerState;
    const { countries, weights, loading: countryLoading, error: errorCountry } = countryState;
    const { fetchWeights, fetchCountries, fetchProvidersList, updateWeight, addNewCountry } = props;
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        fetchWeights(kind);
    }, [kind]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (!countryLoading && errorCountry) {
            errorNotify('Country error', countryState.error);
            return;
        }
        if (showAddModal && !countryLoading && !errorCountry) {
            setShowAddModal(false);
            defaultNotify('New country successfully added', '');
        }
    }, [countryLoading]);

    const getTitle = (weightKind: WeightKind): string => {
        switch (weightKind) {
            case WeightKind.RevShare: {
                return 'Providers RevShare Weight Map';
            }
            case WeightKind.Bidding: {
                return 'Providers Bidding Weight Map';
            }
            case WeightKind.BiddingInternal: {
                return 'Providers Bidding Internal Weight Map';
            }
            case WeightKind.Transsion: {
                return 'Providers Transsion Weight Map';
            }
            case WeightKind.Vivo: {
                return 'Providers Vivo Weight Map';
            }
            case WeightKind.Oppo: {
                return 'Providers Oppo Weight Map';
            }
            default: {
                return 'Providers Xiaomi Weight Map';
            }
        }
    };

    const handleAdd = () => {
        setShowAddModal(true);
    };

    const isAllowedUser = (): boolean => {
        if (isAdmin(groups)) return true;
        if (kind === WeightKind.RevShare) return isRevShareWriter(groups);
        if (kind === WeightKind.Bidding) return isBiddingWriter(groups);
        if (kind === WeightKind.BiddingInternal) return isBiddingInternalWriter(groups);
        if (kind === WeightKind.Xiaomi) return isXiaomiWriter(groups);
        if (kind === WeightKind.Transsion) return isTranssionWriter(groups);
        if (kind === WeightKind.Vivo) return isVivoWriter(groups);
        if (kind === WeightKind.Oppo) return isOppoWriter(groups);
        return false;
    };

    return (
        <>
            <Title level={2} className="title">{getTitle(kind)}</Title>
            {countries.length > 0
                && (
                    <Row gutter={10} justify="start" align="middle" style={{ margin: 'auto', marginBottom: 16 }}>
                        {isAllowedUser()
                            && (
                                <Col>
                                    <Button onClick={handleAdd} type="primary">Add country</Button>
                                </Col>
                            )}
                        <AddCountry
                            open={showAddModal}
                            setOpen={setShowAddModal}
                            countries={countries}
                            providersList={providersList}
                            addNewCountry={addNewCountry}
                            kind={kind}
                        />
                        <WeightMapTable
                            loading={loadingProvider}
                            error={errorProvider}
                            weights={weights}
                            countriesList={countries}
                            providersList={providersList}
                            updateWeight={updateWeight}
                            kind={kind}
                            isAllowedUser={isAllowedUser}
                        />
                    </Row>
                )}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightMap);
