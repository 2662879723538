import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, CheckboxOptionType, DatePicker, DatePickerProps, Select, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { RootState } from '../../store/reducers';
import { formatYearMonthDay } from '../../helpers/date';
import { FetchPerformanceReportRequest, Performance } from '../../models/performance';
import { PerformanceReportAction } from '../../store/types/performance';
import { Country } from '../../models/country';
import CountrySelect from '../common/CountrySelect';
import { ProviderListItem } from '../../models/provider';
import ProviderSelect from '../common/ProviderSelect';
import TDMSelect from '../common/TDMSelect';
import BundleSelect from '../common/BundleSelect';
import { AWSRegionTypeMap } from '../../models/awsRegion';
import AWSRegionSelect from '../common/AWSRegionSelect';
import XLSDownload from '../common/XLSXDownload';
import UserCategorySelect from '../common/UserCategorySelect';
import ZoneSelect from '../common/ZoneSelect';
import UserConsentSelect from '../common/UserConsentSelect';
import ThunkArguments from '../../store/types/common';
import UserIPSelect from '../common/UserIPSelect';
import { KindApp } from '../../models/bundle';
import KindAppSelect from '../common/KindAppSelect';
import TemplateTypeSelect from '../common/TemplateTypeSelect';
import TruncateSelect from '../common/TruncateSelect';
import { Truncate } from '../../models/truncate';

const { Text } = Typography;
const { Option } = Select;

interface PerformanceReportBarProps {
    fetchPerformanceReport:
    (req: FetchPerformanceReportRequest) => (dispatch: Dispatch<PerformanceReportAction>,
        getState: () => RootState, args: ThunkArguments) => Promise<void>;
    performanceStats: Performance[];
    countriesList: Country[];
    providersList: ProviderListItem[];
    groupByOptions: Record<string, string>;
    onGroupByChange: (checkedValues: CheckboxOptionType[]) => void;
}

const dateFormat = 'YYYY-MM-DD';

const PerformanceReportBar: React.FC<PerformanceReportBarProps> = props => {
    const { fetchPerformanceReport, performanceStats, countriesList, groupByOptions,
        onGroupByChange, providersList } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatYearMonthDay(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatYearMonthDay(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [tdm, setTDM] = useState<string | undefined>(undefined);
    const [bundle, setBundle] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [kindApp, setKindApp] = useState<string | undefined>(KindApp['Huawei AdExchange']);
    const [checkedValues, setCheckedValues] = useState<CheckboxOptionType[]>([]);
    const [awsRegion, setAWSRegion] = useState<string | undefined>(undefined);
    const [userCategory, setUserCategory] = useState<string | undefined>(undefined);
    const [zone, setZone] = useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userConsent, setUserConsent] = useState<string | undefined>(undefined);
    const [userIP, setUserIP] = useState<string | undefined>(undefined);
    const [templateType, setTemplateType] = useState<string | undefined>(undefined);
    const [truncate, setTruncate] = useState<string>(Truncate.Daily);

    const tdmChange = (value: string) => setTDM(value);
    const bundleChange = (value: string) => setBundle(value);
    const kindAppChange = (value: string) => setKindApp(value);
    const awsRegionChange = (value: string) => setAWSRegion(value);
    const userCategoryChange = (value: string) => setUserCategory(value);
    const zoneChange = (value: string) => setZone(value);
    const templateTypeChange = (value: string) => setTemplateType(value);
    const truncateChange = (value: string) => setTruncate(value);
    const checkedValueChange = (value: CheckboxOptionType[]) => setCheckedValues(value);

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setFrom(dateString);
        }
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setTo(dateString);
        }
    };

    const getProvidersList = (): ProviderListItem[] => (
        providersList.concat([{
            id: 0,
            provider_name: 'squid',
            display_name: 'squid',
            allowed_countries: [],
        }])
    );

    const search = () => {
        setShowErrorMessage(false);
        if (awsRegion !== undefined && !(awsRegion in AWSRegionTypeMap)) {
            setShowErrorMessage(true);
            setErrorMessage('AWS Region is invalid');
            return;
        }
        let groupBy = checkedValues;
        if (truncate) {
            groupBy = truncate === Truncate.Daily
                ? [...checkedValues, 'date'] as CheckboxOptionType[]
                : [...checkedValues, truncate].filter(item => item !== 'date') as CheckboxOptionType[];
            const fromDate = new Date(from);
            if (truncate === Truncate.Weekly && fromDate.getDay() !== 1) {
                setShowErrorMessage(true);
                setErrorMessage('From date should be Monday');
                return;
            } if (truncate === Truncate.Monthly && fromDate.getDate() !== 1) {
                setShowErrorMessage(true);
                setErrorMessage('From date should be the first day of the month');
                return;
            }
        }
        onGroupByChange([...new Set(groupBy)]);
        const req: FetchPerformanceReportRequest = {
            from,
            to,
            region: awsRegion,
            country,
            provider,
            traffic_distribute_mode: tdm,
            kind_app: kindApp,
            bundle,
            user_category: userCategory,
            user_consent: userConsent,
            zone,
            user_ip: userIP,
            template_type: templateType,
            groupBy: groupBy.map(item => item.toString()),
        };
        fetchPerformanceReport(req);
    };

    const renderGroupBy = () => (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '300px' }}
            onChange={checkedValueChange}
        >
            {[...Object.keys(groupByOptions)]
                .map(key => <Option key={key} value={key}>{groupByOptions[key]}</Option>)}
        </Select>
    );

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '90%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>From: </Text>
                    <DatePicker onChange={onFromChange} defaultValue={dayjs(getDefaultFrom(), dateFormat)} />
                </Col>
                <Col>
                    <Text>To: </Text>
                    <DatePicker onChange={onToChange} defaultValue={dayjs(getDefaultTo(), dateFormat)} />
                </Col>
                <Col>
                    <TruncateSelect value={truncate} onChange={truncateChange} />
                </Col>
                <Col>
                    <AWSRegionSelect onChange={awsRegionChange} />
                </Col>
                <Col>
                    {providersList.length && <ProviderSelect providers={getProvidersList()} onChange={setProvider} />}
                </Col>
                <Col>
                    <ZoneSelect value={zone} onChange={zoneChange} />
                </Col>
                <Col>
                    <CountrySelect countries={countriesList} onChange={setCountry} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '90%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Traffic distribute mode: </Text><TDMSelect value={tdm} onChange={tdmChange} />
                </Col>
                <Col>
                    <Text>API: </Text><KindAppSelect value={kindApp} onChange={kindAppChange} />
                </Col>
                <Col>
                    <Text>User category: </Text>
                    <UserCategorySelect value={userCategory} onChange={userCategoryChange} />
                </Col>
                <Col>
                    <Text>User consent: </Text><UserConsentSelect value={userConsent} onChange={setUserConsent} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '90%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Bundle: </Text><BundleSelect onChange={bundleChange} />
                </Col>
                <Col>
                    <Text>Contains user IP:</Text>  <UserIPSelect value={userIP} onChange={setUserIP} />
                </Col>
                <Col>
                    <Text>
                        Template type:
                    </Text> <TemplateTypeSelect value={templateType} onChange={templateTypeChange} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '90%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Breakdowns: </Text>{renderGroupBy()}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '90%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">
                        Search
                    </Button>
                </Col>
                <Col>
                    {performanceStats.length > 0 && (
                        <XLSDownload
                            data={performanceStats}
                            filename="performance_report.xlsx"
                            breakdownOptions={Object.keys(groupByOptions)}
                            breakdowns={checkedValues.map(value => value.toString())}
                        />
                    )}
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
        </>
    );
};

export default PerformanceReportBar;
