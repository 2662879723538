import countriesMap from './countriesMap';
import { ProviderName } from './provider';

interface Country {
    full_name: string;
    short_name: string;
}

export const getCountryFullName = (code: string) => {
    const country = countriesMap[code];

    if (!country) return code;

    return country;
};

export const sortCountries = (a: Country, b: Country) => a.full_name.localeCompare(b.full_name);

interface Weight {
    active: boolean;
    country: string;
    weight_kind: WeightKind;
    weights?: Record<ProviderName, number>;
    bid_floor_price_scale: number;
    provider_price_scale: number;
    updated_at?: string;
    updated_by: string;
}

interface FactWeight {
    bundle: string;
    country: string;
    weight_kind: FactWeightsKinds;
    weights?: Record<ProviderName, number>;
    date: string;
}

export enum WeightKind {
    RevShare = 'rev_share',
    Bidding = 'bidding',
    BiddingInternal = 'bidding_internal',
    Xiaomi = 'xiaomi',
    Transsion = 'transsion',
    Vivo = 'vivo',
    Oppo = 'oppo'
}

export enum FactWeightsKinds {
    RevShare = 'rev_share',
    Bidding = 'bidding',
    BiddingInternal = 'bidding_internal',
    ByBundle = 'by_bundle',
}

export const TDMWeightKindMap: Record<string, string> = {
    0: WeightKind.Bidding,
    1: WeightKind.RevShare,
};

export const WeightKindMap: Partial<Record<FactWeightsKinds, string>> = {
    rev_share: 'RevShare',
    bidding: 'Bidding',
    bidding_internal: 'Bidding Internal',
    by_bundle: 'By Bundle',
};

export const defaultBidFloorPriceScale = 5;
export const defaultProviderPriceScale = 4;

export type {
    Country,
    Weight,
    FactWeight,
};
