import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { WeightKindMap } from '../../models/country';

const { Option } = Select;

interface WeightKindSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const WeightKindSelect: React.FC<WeightKindSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onWeightKindSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 150 }}
            optionFilterProp="children"
            placeholder="Select weight kind"
            onSelect={onWeightKindSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.entries(WeightKindMap)]
                .map(([key, val]) => <Option key={key} value={key}>{val}</Option>)}
        </Select>
    );
};

export default WeightKindSelect;
