import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getCountryFullName, FactWeight, FactWeightsKinds } from '../../models/country';
import { ProviderListItem, ProviderName } from '../../models/provider';

interface factWeightMapColumn {
    bundle: string;
    country: string;
    countryCode: string;
    failedRequests: number;
}

interface FactWeightMapTableProps {
    loading: boolean;
    error: null | string;
    weightKind: undefined | string;
    factWeights: FactWeight[];
    providersList: ProviderListItem[];
    groupByBundle: boolean;
}

const FactWeightMapTable: React.FC<FactWeightMapTableProps> = props => {
    const { loading, weightKind, factWeights, providersList, groupByBundle } = props;

    const failedRequestsPercent = (providerWeights: Record<ProviderName, number> | undefined) => {
        let sum = 0;
        Object.values(providerWeights!).forEach((weight) => {
            sum += weight;
        });
        return 100 - sum;
    };

    const getFactWeightMap = (): factWeightMapColumn[] => (
        factWeights.map(weight => {
            const providerWeights = weight.weights;
            const { country, bundle } = weight;
            return {
                country: getCountryFullName(country),
                bundle,
                countryCode: country,
                failedRequests: failedRequestsPercent(providerWeights),
                ...providerWeights,
            };
        })
    );

    const getProvidersColumns = (): ColumnsType<factWeightMapColumn> => (
        providersList.map(({ provider_name: providerName, display_name: displayName }) => ({
            title: displayName,
            dataIndex: providerName,
            key: providerName,
            align: 'right',
            render: percent => percent ? `${percent}%` : '',
        }
        ))
    );

    const columns: ColumnsType<factWeightMapColumn> = [
        {
            title: 'Country Name',
            dataIndex: 'country',
            key: 'country',
            align: 'left',
            fixed: 'left',
            width: '10%',
            sorter: (a, b) => a.country.localeCompare(b.country),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Failed Requests',
            dataIndex: 'failedRequests',
            key: 'failedRequests',
            align: 'left',
            fixed: 'left',
            width: '10%',
            render: percent => percent >= 0 ? `${percent.toFixed(2)}%` : `${0}%`,
        },
    ];

    if (weightKind === FactWeightsKinds.ByBundle || groupByBundle) {
        columns.push({
            title: 'Bundle Name',
            dataIndex: 'bundle',
            key: 'bundle',
            width: '20%',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.bundle.localeCompare(b.bundle),
            defaultSortOrder: 'ascend',
        });
    }
    columns.push(...getProvidersColumns());

    return (
        <Table
            className="factweightmap__table"
            style={{ width: '100%', margin: 'auto', whiteSpace: 'nowrap' }}
            bordered
            loading={loading}
            rowKey={record => record.country + record.bundle}
            dataSource={getFactWeightMap()}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{ x: 1500, y: '64vh' }}
        />
    );
};

export default FactWeightMapTable;
