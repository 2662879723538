import { useState } from 'react';
import { Form, InputNumber, FormInstance, Alert, Checkbox } from 'antd';
import { ProviderListItem } from '../../models/provider';
import { Weight, WeightKind } from '../../models/country';

interface CountryWeightFormProps {
    form: FormInstance<Weight>;
    weight: Weight;
    providersList: ProviderListItem[];
    onFinish: ((values: any) => void);
}

const CountryWeightForm: React.FC<CountryWeightFormProps> = props => {
    const { weight, providersList, onFinish } = props;
    const [validateError, setValidateError] = useState<string>();
    const [isActive, setIsActive] = useState(weight.active);
    const weightRegex = /^[0-9][0-9]?$|^100$/;
    const minPriceScale = 0;
    const maxPriceScale = 200;

    const onFinishFailed = (errorInfo: any) => console.log('Failed:', errorInfo);

    const weightRules = [
        { pattern: weightRegex, message: 'Please input a positive integer number! (in percent)' },
    ];

    const validate = (values: { [key: string]: string | boolean }) => {
        setValidateError('');
        // since we wanted to use dynamic fields from db so bad, I'm unable
        // to use standard antd form.group, so enjoy these kludges
        const weights = Object.fromEntries(
            Object.entries(values).filter(w => providersList.some(e => e.provider_name === w[0])),
        );
        const sum = Object.values(weights).reduce((prev, current) => prev + (+current || 0), 0);
        if (sum !== 100) {
            setValidateError('Sum of providers weight must be 100');
            return;
        }
        const bidFloorPriceScale: number = +values.bid_floor_price_scale;
        if (bidFloorPriceScale < minPriceScale || bidFloorPriceScale > maxPriceScale) {
            setValidateError(`Bid floor price scale must be between ${minPriceScale} and ${maxPriceScale}`);
            return;
        }
        const providerPriceScale: number = +values.provider_price_scale;
        if (providerPriceScale < minPriceScale || providerPriceScale > maxPriceScale) {
            setValidateError(`Provider price scale must be between ${minPriceScale} and ${maxPriceScale}`);
            return;
        }
        onFinish({
            active: values.active,
            weights,
            bid_floor_price_scale: bidFloorPriceScale,
            provider_price_scale: providerPriceScale,
        });
    };

    const percentParser = (value: string | undefined): number => value ? +value.replace('%', '') : 0;

    const renderProvidersWithWeights = () => {
        const { weights } = weight;
        return providersList
            .map(({ provider_name: providerName, display_name: displayName }) => (
                <Form.Item
                    name={providerName}
                    label={displayName}
                    key={providerName}
                    initialValue={weights ? weights[providerName] : 0}
                    labelAlign="left"
                    rules={weightRules}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={value => value ? `${value}%` : ''}
                        parser={percentParser}
                        disabled={!isActive}
                    />
                </Form.Item>
            ));
    };

    const isBiddingKind = () => weight.weight_kind === WeightKind.Bidding || weight.weight_kind
        === WeightKind.BiddingInternal;

    const renderPriceScales = () => (
        [
            {
                label: 'Bid floor price scale',
                name: 'bid_floor_price_scale',
            },
            {
                label: 'Provider price scale',
                name: 'provider_price_scale',
            },
        ].map(({ label, name }) => (
            <Form.Item
                name={name}
                label={label}
                key={name}
                labelAlign="left"
                initialValue={weight[name as keyof Weight]}
            >
                <InputNumber
                    min={minPriceScale}
                    max={maxPriceScale}
                    formatter={value => value ? `${value}%` : ''}
                    parser={percentParser}
                    disabled={!isActive}
                />
            </Form.Item>
        ))
    );

    return (
        <Form
            id={weight.country}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ active: isActive }}
            onFinish={validate}
            onFinishFailed={onFinishFailed}
        >
            {validateError && <Alert style={{ marginBottom: '20px' }} message={validateError} type="error" />}
            <Form.Item
                name="active"
                valuePropName="checked"
                wrapperCol={{ offset: 4, span: 16 }}
            >
                <Checkbox onChange={e => setIsActive(e.target.checked)}>Active</Checkbox>
            </Form.Item>
            {isBiddingKind() && renderPriceScales()}
            {renderProvidersWithWeights()}
        </Form>
    );
};

export default CountryWeightForm;
