import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Button, DatePicker, DatePickerProps, Typography, Row, Col, Checkbox } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import { WeightKind } from '../../models/country';
import { formatYearMonthDay } from '../../helpers/date';
import FactWeightMapTable from './FactWeightMapTable';
import WeightKindSelect from '../common/WeightKindSelect';
import { FactWeightsReportRequest } from '../../models/factWeightsReport';
import CountrySelect from '../common/CountrySelect';

const { Title, Text } = Typography;
const dateFormat = 'YYYY-MM-DD';

const mapStateToProps = (state: RootState) => ({
    providerState: state.provider,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps;

const FactWeightMap: React.FC<ProviderProps> = props => {
    const { providerState, countryState } = props;
    const { providersList, error: errorProvider, loading: loadingProvider } = providerState;
    const { factWeights, countries, error: errorCountry } = countryState;
    const { fetchFactWeights, fetchCountries, fetchProvidersList } = props;

    const getDefaultDate = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatYearMonthDay(date.toString());
    };

    const [date, setDate] = useState(getDefaultDate());
    const [weightKind, setWeightKind] = useState<WeightKind | undefined>(WeightKind.RevShare);
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [groupByBundle, setGroupByBundle] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    const onDateChange: DatePickerProps['onChange'] = (d, dateString) => {
        if (typeof dateString === 'string') {
            setDate(dateString);
        }
    };
    const weightKindChange = (value: string) => setWeightKind(value as WeightKind);

    const search = () => {
        setShowErrorMessage(false);
        if (!date) {
            setShowErrorMessage(true);
            setErrorMessage('Date is required');
            return;
        }
        if (weightKind === undefined) {
            setShowErrorMessage(true);
            setErrorMessage('Weight kind is required');
            return;
        }
        const req: FactWeightsReportRequest = {
            date,
            kind: weightKind,
            country,
            group_by_bundle: groupByBundle,
        };
        fetchFactWeights(req);
    };

    return (
        <>
            <Title level={2} className="title">Fact Weights</Title>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Date: </Text>
                    <DatePicker onChange={onDateChange} defaultValue={dayjs(getDefaultDate(), dateFormat)} />
                </Col>
                <Col>
                    <Text>Weight kind: </Text><WeightKindSelect value={weightKind} onChange={weightKindChange} />
                </Col>
                <Col>
                    <Text>Country: </Text><CountrySelect countries={countries} onChange={setCountry} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16, paddingLeft: 5 }}
            >
                <Text style={{ marginRight: 4 }}>Breakdown by Bundle: </Text>
                <Checkbox onChange={e => setGroupByBundle(e.target.checked)} />
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
            <FactWeightMapTable
                loading={loadingProvider}
                error={errorProvider}
                weightKind={weightKind}
                factWeights={factWeights}
                groupByBundle={groupByBundle}
                providersList={providersList}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FactWeightMap);
