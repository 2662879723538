import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import * as IncomingReportActionCreators from '../../store/actions/incomingReportAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import IncomingReportTable from './IncomingReportTable';
import IncomingReportBar from './IncomingReportBar';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    countryState: state.country,
    incomingReportState: state.incomingReport,
    providerState: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
        ...IncomingReportActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps;

const IncomingReport: React.FC<ProviderProps> = props => {
    const { userState, countryState, incomingReportState, providerState } = props;
    const { groups } = userState;
    const { countries, error: errorCountry } = countryState;
    const { providersList, error: errorProvider } = providerState;
    const { incomingInfoStats, error: errorIncomingReport, loading } = incomingReportState;
    const { fetchCountries, fetchIncomingReport, fetchProvidersList } = props;

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (errorProvider) errorNotify('Providers error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorIncomingReport) errorNotify('Incoming report error', errorIncomingReport);
    }, [errorIncomingReport]);

    return (
        <>
            <Title level={2} className="title">Incoming Report by CrID</Title>
            <IncomingReportBar
                fetchIncomingReport={fetchIncomingReport}
                incomingStats={incomingInfoStats}
                countries={countries}
                providers={providersList}
            />
            <IncomingReportTable
                loading={loading}
                error={errorIncomingReport}
                incomingStats={incomingInfoStats}
                countriesList={countries}
                groups={groups}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingReport);
