import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { KindApp } from '../../models/bundle';

const { Option } = Select;

interface KindAppSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const KindAppSelect: React.FC<KindAppSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onKindAppSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };
    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 200 }}
            optionFilterProp="children"
            placeholder="Select kind app"
            onSelect={onKindAppSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.entries(KindApp)].map(
                item => <Option key={item[1]} value={item[1]}>{item[0]}</Option>,
            )}
        </Select>
    );
};

export default KindAppSelect;
