import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName, TDMWeightKindMap } from '../../models/country';
import { UserGroups } from '../../models/user';
import { compareDates, formatYearMonthDay } from '../../helpers/date';
import { LURLStat } from '../../models/lurl';
import { withKeys } from '../../helpers/array';
import { UserIPMap } from '../../models/userIP';
import { compareValues } from '../../helpers/compare';
import { getProviderDisplayName } from '../../helpers/provider';

interface lurlReportColumn {
    key: string;
    date: string;
    kind_app: string;
    tdm: string;
    reason: string;
    error_reason: string;
    total: number;
    country: string;
    zone: string;
    user_category: string;
    provider: string;
    user_ip: number;
    bundle: string;
}

interface LURLReportTableProps {
    loading: boolean;
    error: null | string;
    lurlStats: LURLStat[];
    countries: Country[];
    groups: UserGroups[];
    groupByList: string[];
}

const LURLReportTable: React.FC<LURLReportTableProps> = props => {
    const { loading, lurlStats, groupByList } = props;
    const defaultPageSize = 30;

    const getColumns = (): ColumnsType<lurlReportColumn> => {
        const groupColumns: ColumnsType<lurlReportColumn> = [
            {
                title: 'API',
                dataIndex: 'kind_app',
                key: 'kind_app',
                align: 'left',
                fixed: 'left',
                width: 80,
                sorter: (a, b) => compareValues(a.kind_app, b.kind_app),
            },
            {
                title: 'Country Name',
                dataIndex: 'country',
                key: 'country',
                align: 'left',
                sorter: (a, b) => a.country.localeCompare(b.country),
                render: (country: string) => getCountryFullName(country),
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                align: 'right',
                sorter: (a, b) => a.provider.localeCompare(b.provider),
                render: provider => getProviderDisplayName(provider),
            },
            {
                title: 'Traffic distribute mode',
                dataIndex: 'tdm',
                key: 'tdm',
                align: 'left',
                sorter: (a, b) => a.tdm.toString().localeCompare(b.tdm.toString()),
                render: (tdm: string): string => TDMWeightKindMap[tdm],
            },
            {
                title: 'Zone',
                dataIndex: 'zone',
                key: 'zone',
                align: 'left',
                sorter: (a, b) => a.zone.localeCompare(b.zone),
            },
            {
                title: 'User category',
                dataIndex: 'user_category',
                key: 'user_category',
                align: 'left',
                sorter: (a, b) => a.user_category.toString().localeCompare(b.user_category.toString()),
            },
            {
                title: 'User IP',
                dataIndex: 'user_ip',
                key: 'user_ip',
                align: 'left',
                width: 100,
                sorter: (a, b) => a.user_ip.toString().localeCompare(b.user_ip.toString()),
                render: (userIP: string): string => UserIPMap[userIP],
            },
            {
                title: 'Bundle',
                dataIndex: 'bundle',
                key: 'bundle',
                align: 'left',
                fixed: 'left',
                sorter: (a, b) => compareValues(a.bundle, b.bundle),
            },
        ];
        const filtered = groupColumns.filter(item => groupByList.includes(item.key!.toString()));
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                align: 'right',
                sorter: (a, b) => compareDates(a.date || '', b.date || ''),
                defaultSortOrder: 'ascend',
                render: date => <span>{formatYearMonthDay(date)}</span>,
            },
            ...filtered,
            {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
                align: 'right',
            },
            {
                title: 'Error reason',
                dataIndex: 'error_reason',
                key: 'error_reason',
                align: 'right',
                sorter: (a, b) => a.error_reason.toString().localeCompare(b.error_reason.toString()),
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                sorter: (a, b) => a.total - b.total,
            },
        ];
    };

    return (
        <Table
            className="lurlreport__table"
            style={{ width: '80%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(lurlStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
        />
    );
};

export default LURLReportTable;
