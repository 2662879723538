import React, { useEffect } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ConfigProvider, theme } from 'antd';
import { RootState } from './store/reducers';
import { firstLogin } from './store/actions/userAction';
import Login from './components/auth/Login';
import Page from './components/Page';
import { ContextProvider } from './Context';
import useLocalStorage from './hooks/useLocalStorage';
import { isTechteam } from './models/user';

const mapStateToProps = (state: RootState) => ({ userState: state.user });

const mapDispatchToProps = (dispatch: Dispatch) => {
    const userActions = bindActionCreators({ firstLogin }, dispatch);
    return {
        ...userActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type AppProps = StateProps & DispatchProps;

const { darkAlgorithm, defaultAlgorithm } = theme;

const App: React.FC<AppProps> = props => {
    const [isDarkMode, setIsDarkMode] = useLocalStorage('isDarkMode', false);

    const { userState } = props;
    const { isLoggedIn, user } = userState;
    const { firstLogin: firstUserLogin } = props;
    const getUserEmail = () => {
        const payload = (user as CognitoUser)?.getSignInUserSession()?.getIdToken().decodePayload();
        return payload && payload.email;
    };

    useEffect(() => {
        firstUserLogin(user);
    }, [firstUserLogin, user]);

    return (
        <div className="app">
            {isLoggedIn
                ? (
                    <ContextProvider value={{
                        email: (user as CognitoUser)?.getUsername(),
                        isTechteam: isTechteam(getUserEmail()),
                        isDarkMode,
                        setIsDarkMode,
                    }}
                    >
                        <ConfigProvider
                            theme={{
                                algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                            }}
                        >
                            <Page />
                        </ConfigProvider>
                    </ContextProvider>
                )
                : <Login />}
        </div>
    );
};

App.defaultProps = {
    userState: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
