import { useContext, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { Country, getCountryFullName } from '../../models/country';
import { CreateAction } from '../../store/types/actions';
import { ProviderListItem, ProviderName } from '../../models/provider';
import { Context } from '../../Context';
import { defaultNotify } from '../common/Notify';
import Device from '../../models/device';
import DeviceForm from '../forms/DeviceForm';

const { useForm } = Form;

interface AddDeviceProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    countries: Country[];
    providersList: ProviderListItem[];
    error: null | string;
    addDevice: CreateAction<Device, void>;
}

const AddDevice: React.FC<AddDeviceProps> = props => {
    const { open, setOpen, countries, providersList, addDevice, error } = props;
    const { email } = useContext(Context);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = useForm<Device>();

    const handleOk = async (values: { [key: string]: any }) => {
        const { deviceID, deviceUser, country, weights } = values;
        const weightsMap = weights
            .filter((w: { provider: ProviderName; weight: number; }) => w.weight > 0)
            .reduce((
                acc: { provider: ProviderName; weight: number; },
                cur: { provider: ProviderName; weight: number; },
            ) => ({ ...acc, [cur.provider]: cur.weight }), {});
        const newDevice: Device = {
            device_id: deviceID,
            device_user: deviceUser,
            country,
            weights: weightsMap,
            updated_by: email,
            updated_at: new Date().toISOString(),
        };
        setConfirmLoading(true);
        await addDevice(newDevice);
        setConfirmLoading(false);
        if (!error) {
            setOpen(false);
            defaultNotify(`${deviceID} for ${getCountryFullName(country)} added`, '');
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Modal
            title="Add Device"
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            getContainer={false}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button form="deviceForm" key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <DeviceForm
                form={form}
                countries={countries}
                providersList={providersList}
                onFinish={handleOk}
            />
        </Modal>
    );
};

export default AddDevice;
