export interface LoginValues {
    username: string;
    password: string;
}

export enum UserGroups {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER = 'ROLE_USER',
    ROLE_WEIGHT_BIDDING_WRITER = 'ROLE_WEIGHT_BIDDING_WRITER',
    ROLE_WEIGHT_BIDDING_INTERNAL_WRITER = 'ROLE_WEIGHT_BIDDING_INTERNAL_WRITER',
    ROLE_WEIGHT_REV_SHARE_WRITER = 'ROLE_WEIGHT_REV_SHARE_WRITER',
    ROLE_WEIGHT_XIAOMI_WRITER = 'ROLE_WEIGHT_XIAOMI_WRITER',
    ROLE_WEIGHT_TRANSSION_WRITER = 'ROLE_WEIGHT_TRANSSION_WRITER',
    ROLE_WEIGHT_VIVO_WRITER = 'ROLE_WEIGHT_VIVO_WRITER',
    ROLE_WEIGHT_OPPO_WRITER = 'ROLE_WEIGHT_OPPO_WRITER',
    ROLE_BUNDLE_WRITER = 'ROLE_BUNDLE_WRITER',
    ROLE_ADBLOCK_DELETER = 'ROLE_ADBLOCK_DELETER',
    ROLE_DEVICE_WRITER = 'ROLE_DEVICE_WRITER',
}

export const isAdmin = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_ADMIN);
export const isUser = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_USER);
export const isBiddingWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_BIDDING_WRITER);
export const isBiddingInternalWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_BIDDING_INTERNAL_WRITER);
export const isRevShareWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_REV_SHARE_WRITER);
export const isXiaomiWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_XIAOMI_WRITER);
export const isTranssionWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_TRANSSION_WRITER);
export const isVivoWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_VIVO_WRITER);
export const isOppoWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_OPPO_WRITER);
export const isBundleWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_BUNDLE_WRITER);
export const isAdblockDeleter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_ADBLOCK_DELETER);
export const isDeviceWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_DEVICE_WRITER);

enum UserEmails {
    bakhtiyar = 'bakhtiyar@squidapp.co',
    maxim = 'maxim.vinogradov@fastdev.se',
    ivanN = 'ivan.nikitin@fastdev.se',
    ivanL = 'ivan.lapshin@fastdev.se',
    denis = 'denis.popov@fastdev.se',
    aidyn = 'aidyn@squidapp.co',
    maria = 'mariaspiiish@gmail.com'
}

export const isTechteam = (email: string) => Object.values(UserEmails).includes(email as UserEmails);
