const addLeadingZero = (input: number) => input.toString().padStart(2, '0');
const getMonth = (date: Date) => addLeadingZero((date.getMonth() + 1));

/**
 * Formats date string to readable date string
 * @param input date string
 * @returns YYYY-MM
 */
const formatYearMonth = (input: string) => {
    const date = new Date(input);
    if (Number.isNaN(date.valueOf())) return 'invalid date';

    return `${date.getFullYear()}-${getMonth(date)}`;
};

/**
 * Formats date string to readable date string
 * @param input date string
 * @returns YYYY-MM-DD
 */
const formatYearMonthDay = (input: string) => {
    const date = new Date(input);
    if (Number.isNaN(date.valueOf())) return 'invalid date';
    const ym = formatYearMonth(input);
    return `${ym}-${addLeadingZero(date.getDate())}`;
};

/**
 * Formats date string to readable date string
 * @param input date string
 * @returns YYYY-MM-DD HH
 */
const formatYearMonthDayHour = (input: string) => {
    const parts = input.split('/');
    const date = new Date(
        parseInt(parts[0], 10), // year
        parseInt(parts[1], 10), // month
        parseInt(parts[2], 10), // day
        parseInt(parts[3], 10), // hour
    );
    if (Number.isNaN(date.valueOf())) return 'invalid date';
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = addLeadingZero(date.getDate());
    const hours = addLeadingZero(date.getHours());
    return `${year}-${month}-${day} ${hours}`;
};

/**
 * Formats date string to readable datetime string
 * @param input date string
 * @returns YYYY-MM-DD HH:MM:SS
 */
const formatDateTime = (input: string) => {
    const date = new Date(input);
    if (Number.isNaN(date.valueOf())) return 'invalid date';
    const d = formatYearMonthDay(input);
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    const seconds = addLeadingZero(date.getSeconds());

    return `${d} ${hours}:${minutes}:${seconds}`;
};

function parseDateString(dateString: string): Date | null {
    const date = new Date(dateString);
    if (!Number.isNaN(date.valueOf())) {
        return date;
    }

    const dateParts = dateString.split('/').map(Number);
    if (dateParts.length === 2 || dateParts.length === 3 || dateParts.length === 4) {
        const [year, month, day = 1, hour = 0] = dateParts;

        if (!Number.isNaN(year) && !Number.isNaN(month) && !Number.isNaN(day) && !Number.isNaN(hour)
            && month >= 1 && month <= 12
            && day >= 1 && day <= 31
            && hour >= 0 && hour <= 23
        ) {
            // month-1 since it's 0-indexed: 0 - january, 1 - february...
            return new Date(year, month - 1, day, hour);
        }
    }

    return null;
}

/**
 * Compares two date strings
 * @param first date string
 * @param second date string
 * @returns number
 */
const compareDates = (first: string, second: string) => {
    const firstDate = parseDateString(first);
    const secondDate = parseDateString(second);
    if (!firstDate || !secondDate) return Number.NaN;

    if (firstDate && secondDate) {
        if (firstDate > secondDate) return 1;
        if (firstDate < secondDate) return -1;
    }
    return 0;
};

export {
    formatDateTime,
    formatYearMonth,
    formatYearMonthDay,
    formatYearMonthDayHour,
    parseDateString,
    compareDates,
};
