import { useState } from 'react';
import { Form, FormInstance, InputNumber, Alert } from 'antd';
import { BundleWeight } from '../../models/bundle';
import { ProviderListItem } from '../../models/provider';

interface BundleWeightUpdateFormProps {
    form: FormInstance<BundleWeight>;
    weight: BundleWeight;
    providersList: ProviderListItem[];
    onFinish: ((values: any) => void);
}

const BundleWeightUpdateForm: React.FC<BundleWeightUpdateFormProps> = props => {
    const { weight, providersList, onFinish } = props;
    const [validateError, setValidateError] = useState<string>();
    const weightRegex = /^[0-9][0-9]?$|^100$/;

    const onFinishFailed = (errorInfo: any) => console.log('Failed:', errorInfo);

    const weightRules = [
        { pattern: weightRegex, message: 'Please input a positive integer number! (in percent)' },
    ];

    const validate = (values: { [key: string]: string | boolean }) => {
        setValidateError('');
        // since we wanted to use dynamic fields from db so bad, I'm unable
        // to use standard antd form.group, so enjoy these kludges
        const weights = Object.fromEntries(
            Object.entries(values).filter(w => providersList.some(e => e.provider_name === w[0])),
        );
        const sum = Object.values(weights).reduce((prev, current) => prev + (+current || 0), 0);
        if (sum !== 100) {
            setValidateError('Sum of bundle providers weight must be 100');
            return;
        }
        onFinish({ weights });
    };

    const percentParser = (value: string | undefined): number => value ? +value.replace('%', '') : 0;

    const renderProvidersWithWeights = () => {
        const { weights } = weight;
        return providersList
            .map(({ provider_name: providerName, display_name: displayName }) => (
                <Form.Item
                    name={providerName}
                    label={displayName}
                    key={providerName}
                    initialValue={weights ? weights[providerName] : 0}
                    labelAlign="left"
                    rules={weightRules}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        formatter={value => value ? `${value}%` : ''}
                        parser={percentParser}
                    />
                </Form.Item>
            ));
    };

    return (
        <Form
            id="bundleWeightUpdateForm"
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={validate}
            onFinishFailed={onFinishFailed}
        >
            {validateError && <Alert style={{ marginBottom: '20px' }} message={validateError} type="error" />}
            {renderProvidersWithWeights()}
        </Form>
    );
};

export default BundleWeightUpdateForm;
