import { AxiosError } from 'axios';
import { BundleAction, BundleActionTypes } from '../types/bundle';
import Bundle, { BundleWeight } from '../../models/bundle';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

export const fetchBundles = (): Action<BundleAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: BundleActionTypes.FETCH_BUNDLES_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<Bundle[]>('third-party-apps/bundles');
            dispatch({ type: BundleActionTypes.FETCH_BUNDLES_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, BundleActionTypes.FETCH_BUNDLES_ERROR, 'Error fetching bundles');
        }
    }
);

export const addBundle = (bundle: Bundle): Action<BundleAction> => async (dispatch, getState, { defaultAPI }) => {
    dispatch({ type: BundleActionTypes.ADD_BUNDLE_REQUEST });

    defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

    return new Promise((resolve, reject) => {
        defaultAPI.post<Bundle>('third-party-apps/bundles', bundle)
            .then(res => {
                dispatch({ type: BundleActionTypes.ADD_BUNDLE_SUCCESS, payload: res.data });
                resolve();
            })
            .catch(e => {
                handleError(dispatch, e, BundleActionTypes.ADD_BUNDLE_ERROR, 'Error adding bundle');
                reject(e);
            });
    });
};

export const updateBundle = (bundle: Bundle): Action<BundleAction> => async (dispatch, getState, { defaultAPI }) => {
    dispatch({ type: BundleActionTypes.UPDATE_BUNDLE_REQUEST });

    defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

    return new Promise((resolve, reject) => {
        defaultAPI.put<Bundle>(`third-party-apps/bundles/${bundle.id}`, bundle)
            .then(res => {
                dispatch({ type: BundleActionTypes.UPDATE_BUNDLE_SUCCESS, payload: res.data });
                resolve();
            })
            .catch(e => {
                handleError(dispatch, e, BundleActionTypes.UPDATE_BUNDLE_ERROR, 'Error updating bundle');
                reject(e);
            });
    });
};

export const deleteBundle = (id: number): Action<BundleAction> => async (dispatch, getState, { defaultAPI }) => {
    dispatch({ type: BundleActionTypes.DELETE_BUNDLE_REQUEST });

    defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

    return new Promise((resolve, reject) => {
        defaultAPI.delete(`third-party-apps/bundles/${id}`)
            .then(() => {
                dispatch({ type: BundleActionTypes.DELETE_BUNDLE_SUCCESS, payload: id });
                resolve();
            })
            .catch(e => {
                handleError(dispatch, e, BundleActionTypes.DELETE_BUNDLE_ERROR, 'Error deleting bundle');
                reject(e);
            });
    });
};

export const fetchBundleWeights = (): Action<BundleAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: BundleActionTypes.FETCH_BUNDLE_WEIGHTS_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<BundleWeight[]>('bundle-weights');
            dispatch({ type: BundleActionTypes.FETCH_BUNDLE_WEIGHTS_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, BundleActionTypes.FETCH_BUNDLE_WEIGHTS_ERROR, 'Error fetching bundle weights');
        }
    }
);

export const addBundleWeight = (bundle: BundleWeight): Action<BundleAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: BundleActionTypes.ADD_BUNDLE_WEIGHT_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.post<BundleWeight>('bundle-weights', bundle);
            dispatch({ type: BundleActionTypes.ADD_BUNDLE_WEIGHT_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, BundleActionTypes.ADD_BUNDLE_WEIGHT_ERROR, 'Error adding bundle weight');
        }
    }
);

export const updateBundleWeight = (bundle: BundleWeight): Action<BundleAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: BundleActionTypes.UPDATE_BUNDLE_WEIGHT_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.put<BundleWeight>(`bundle-weights/${bundle.id}`, bundle);
            dispatch({ type: BundleActionTypes.UPDATE_BUNDLE_WEIGHT_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, BundleActionTypes.UPDATE_BUNDLE_WEIGHT_ERROR, 'Error updating bundle weight');
        }
    }
);

export const deleteBundleWeight = (id: number): Action<BundleAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: BundleActionTypes.DELETE_BUNDLE_WEIGHT_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            await defaultAPI.delete(`bundle-weights/${id}`);
            dispatch({ type: BundleActionTypes.DELETE_BUNDLE_WEIGHT_SUCCESS, payload: id });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, BundleActionTypes.DELETE_BUNDLE_WEIGHT_ERROR, 'Error deleting bundle weight');
        }
    }
);
