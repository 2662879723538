import { createContext, useMemo } from 'react';

interface context {
    email: string;
    isTechteam: boolean;
    isDarkMode: boolean;
    setIsDarkMode: () => void;
}

const defaultContext: context = {
    email: '',
    isTechteam: false,
    isDarkMode: false,
    setIsDarkMode: () => undefined,
};
export const Context = createContext<context>(defaultContext);

interface ContextProviderProps {
    value: context;
    children: React.ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ value, children }) => {
    const { email, isTechteam, isDarkMode, setIsDarkMode } = value;
    const memo = useMemo<context>(
        () => ({ email, isTechteam, isDarkMode, setIsDarkMode }),
        [email, isTechteam, isDarkMode, setIsDarkMode],
    );

    return (
        <Context.Provider value={memo}>
            {children}
        </Context.Provider>
    );
};
