import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Typography, Row, Col } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as BundleActionCreators from '../../store/actions/bundleAction';
import * as CountryActionCreators from '../../store/actions/countryAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import { isAdmin, isBiddingWriter } from '../../models/user';
import AddBundleWeight from '../modals/AddBundleWeight';
import WeightMapPerBundleTable from './WeightMapPerBundleTable';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    providerState: state.provider,
    bundleState: state.bundle,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...BundleActionCreators,
        ...CountryActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type WeightMapPerBundleProps = StateProps & DispatchProps;

const WeightMapPerBundle: React.FC<WeightMapPerBundleProps> = props => {
    const { userState, providerState, bundleState, countryState } = props;
    const { groups } = userState;
    const { providersList, error: errorProvider } = providerState;
    const { bundleWeights, loading: bundleLoading, error: errorBundle } = bundleState;
    const { countries, error: errorCountry } = countryState;
    const { fetchBundleWeights, addBundleWeight, updateBundleWeight, deleteBundleWeight,
        fetchProvidersList, fetchCountries } = props;
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        fetchProvidersList();
        fetchBundleWeights();
        fetchCountries();
    }, []);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (!bundleLoading && errorBundle) {
            errorNotify('Bundle error', errorBundle);
        }
    }, [bundleLoading, errorBundle]);

    const handleAdd = () => {
        setShowAddModal(true);
    };

    const isAllowedUser = (): boolean => isAdmin(groups) ? true : isBiddingWriter(groups);

    return (
        <>
            <Title level={2}>Weights For Bidding Bundles</Title>
            <Row gutter={10} justify="start" align="middle" style={{ margin: 'auto', marginBottom: 16 }}>
                {isAllowedUser()
                    && (
                        <Col>
                            <Button onClick={handleAdd} type="primary">Add Bundle Weight</Button>
                        </Col>
                    )}
                <AddBundleWeight
                    open={showAddModal}
                    setOpen={setShowAddModal}
                    countries={countries}
                    providersList={providersList}
                    error={errorBundle}
                    addBundleWeight={addBundleWeight}
                />
                <WeightMapPerBundleTable
                    loading={bundleLoading}
                    error={errorBundle}
                    weights={bundleWeights}
                    countries={countries}
                    providersList={providersList}
                    groups={groups}
                    updateBundleWeight={updateBundleWeight}
                    deleteBundleWeight={deleteBundleWeight}
                />
            </Row>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightMapPerBundle);
