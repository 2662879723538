import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Typography, Row, Col } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountryActionCreators from '../../store/actions/countryAction';
import * as DeviceActionCreators from '../../store/actions/deviceAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import { isAdmin, isDeviceWriter } from '../../models/user';
import AddDevice from '../modals/AddDevice';
import DevicesTable from './DevicesTable';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    providerState: state.provider,
    deviceState: state.device,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...DeviceActionCreators,
        ...CountryActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type DevicesProps = StateProps & DispatchProps;

const Devices: React.FC<DevicesProps> = props => {
    const { userState, providerState, deviceState, countryState } = props;
    const { groups } = userState;
    const { providersList, error: errorProvider } = providerState;
    const { devices, loading: devicesLoading, error: errorDevices } = deviceState;
    const { countries, error: errorCountry } = countryState;
    const {
        fetchDevices,
        addDevice,
        updateDevice,
        deleteDevice,
        fetchProvidersList,
        fetchCountries,
    } = props;
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        fetchProvidersList();
        fetchDevices();
        fetchCountries();
    }, []);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (!devicesLoading && errorDevices) {
            errorNotify('Devices error', errorDevices);
        }
    }, [devicesLoading, errorDevices]);

    const handleAdd = () => {
        setShowAddModal(true);
    };

    const isAllowedUser = (): boolean => isAdmin(groups) ? true : isDeviceWriter(groups);

    return (
        <>
            <Title level={2}>QA Devices Management</Title>
            <Row gutter={10} justify="start" align="middle" style={{ margin: 'auto', marginBottom: 16 }}>
                {isAllowedUser()
                    && (
                        <Col>
                            <Button onClick={handleAdd} type="primary">Add Device</Button>
                        </Col>
                    )}
                <AddDevice
                    open={showAddModal}
                    setOpen={setShowAddModal}
                    countries={countries}
                    providersList={providersList}
                    error={errorDevices}
                    addDevice={addDevice}
                />
                <DevicesTable
                    loading={devicesLoading}
                    error={errorDevices}
                    devices={devices}
                    countries={countries}
                    providersList={providersList}
                    groups={groups}
                    updateDevice={updateDevice}
                    deleteDevice={deleteDevice}
                />
            </Row>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
