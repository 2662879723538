import { LangRatioReportStat } from '../../models/langRatioReport';

export interface LangRatioReportState {
    langRatioReportStats: LangRatioReportStat[];
    loading: boolean;
    error: null | string;
}

export enum LangRatioReportActionTypes {
    FETCH_LANG_RATIO_REPORT_REQUEST = 'FETCH_LANG_RATIO_REPORT_REQUEST',
    FETCH_LANG_RATIO_REPORT_SUCCESS = 'FETCH_LANG_RATIO_REPORT_SUCCESS',
    FETCH_LANG_RATIO_REPORT_ERROR = 'FETCH_LANG_RATIO_REPORT_ERROR',
}

interface FetchLangRatioReportRequestAction {
    type: LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_REQUEST;
}

interface FetchLangRatioReportSuccessAction {
    type: LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_SUCCESS;
    payload: LangRatioReportStat[];
}

interface FetchLangRatioReportErrorAction {
    type: LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_ERROR;
    payload: string;
}
export type LangRatioReportAction = FetchLangRatioReportRequestAction |
    FetchLangRatioReportSuccessAction |
    FetchLangRatioReportErrorAction;
