import { AxiosError } from 'axios';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';
import { DeviceAction, DeviceActionTypes } from '../types/device';
import Device from '../../models/device';

export const fetchDevices = (): Action<DeviceAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: DeviceActionTypes.FETCH_DEVICES_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<Device[]>('device-weights');
            dispatch({ type: DeviceActionTypes.FETCH_DEVICES_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, DeviceActionTypes.FETCH_DEVICES_ERROR, 'Error fetching devices');
        }
    }
);

export const addDevice = (device: Device): Action<DeviceAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: DeviceActionTypes.ADD_DEVICE_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.post<Device>('device-weights', device);
            dispatch({ type: DeviceActionTypes.ADD_DEVICE_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, DeviceActionTypes.ADD_DEVICE_ERROR, 'Error adding device');
        }
    }
);

export const updateDevice = (device: Device): Action<DeviceAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: DeviceActionTypes.UPDATE_DEVICE_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.put<Device>(`device-weights/${device.id}`, device);
            dispatch({ type: DeviceActionTypes.UPDATE_DEVICE_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, DeviceActionTypes.UPDATE_DEVICE_ERROR, 'Error updating device');
        }
    }
);

export const deleteDevice = (id: number): Action<DeviceAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: DeviceActionTypes.DELETE_DEVICE_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            await defaultAPI.delete(`device-weights/${id}`);
            dispatch({ type: DeviceActionTypes.DELETE_DEVICE_SUCCESS, payload: id.toString() });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, DeviceActionTypes.DELETE_DEVICE_ERROR, 'Error deleting device');
        }
    }
);
