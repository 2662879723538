import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { LinkOutlined } from '@ant-design/icons';
import { getCountryFullName } from '../../models/country';
import { withKeys } from '../../helpers/array';
import { compareDates, formatYearMonthDay } from '../../helpers/date';
import { compareValues } from '../../helpers/compare';
import { AdblockReportStat } from '../../models/adblock';
import { DeleteAction } from '../../store/types/actions';
import { isAdblockDeleter, isAdmin, UserGroups } from '../../models/user';
import AdblockReportActions from './AdblockReportActions';
import { getProviderDisplayName } from '../../helpers/provider';

interface adblockReportColumn {
    key: string;
    date: string;
    cr_id: string;
    country?: string;
    provider?: string;
    zone?: string;
    image: string;
    title: string;
    link: string;
    block_reason: string;
    total: number;
}

interface AdblockReportTableProps {
    loading: boolean;
    error: null | string;
    adblockReportStats: AdblockReportStat[];
    groups: UserGroups[];
    unblockCrID: DeleteAction;
}

const AdblockReportTable: React.FC<AdblockReportTableProps> = props => {
    const { loading, adblockReportStats, groups, unblockCrID } = props;
    const defaultPageSize = 30;

    const isAllowedUser = (): boolean => isAdmin(groups) ? true : isAdblockDeleter(groups);

    const fixUrl = (url: string): string => {
        const urlPattern = /^(https?:\/\/)/i;

        if (!urlPattern.test(url)) {
            return `https://${url}`;
        }

        return url;
    };

    const getColumns = (): ColumnsType<adblockReportColumn> => [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'right',
            width: 70,
            sorter: (a, b) => compareDates(a.date || '', b.date || ''),
            render: date => <span style={{ whiteSpace: 'nowrap' }}>{formatYearMonthDay(date)}</span>,
        },
        {
            title: 'Creative ID',
            dataIndex: 'cr_id',
            key: 'cr_id',
            align: 'right',
            width: 70,
        },
        {
            title: 'Country Name',
            dataIndex: 'country',
            key: 'country',
            align: 'left',
            width: 100,
            sorter: (a, b) => a.country && b.country ? compareValues(a.country, b.country) : 0,
            render: (country: string) => getCountryFullName(country),
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            key: 'provider',
            align: 'left',
            width: 70,
            sorter: (a, b) => a.provider && b.provider ? compareValues(a.provider, b.provider) : 0,
            render: provider => getProviderDisplayName(provider),
        },
        {
            title: 'Zone',
            dataIndex: 'zone',
            key: 'zone',
            align: 'left',
            width: 150,
            sorter: (a, b) => a.zone && b.zone ? compareValues(a.zone, b.zone) : 0,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'left',
            width: 500,
            sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            align: 'left',
            width: 100,
            render: image => (
                <a rel="noreferrer" target="_blank" href={fixUrl(image)}>Image
                    link <LinkOutlined />
                </a>
            ),
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            align: 'left',
            width: 70,
            render: link => link ? <a rel="noreferrer" target="_blank" href={link}>Link <LinkOutlined /></a> : '',
        },
        {
            title: 'Block Reason',
            dataIndex: 'block_reason',
            key: 'block_reason',
            align: 'left',
            width: 400,
            sorter: (a, b) => compareValues(a.block_reason, b.block_reason),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'left',
            width: 70,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.total - b.total,
            render: total => total.toLocaleString(),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 20,
            render: record => isAllowedUser()
                ? (
                    <AdblockReportActions
                        adblocked={record}
                        unblockCrID={unblockCrID}
                    />
                ) : null,
        },
    ];

    return (
        <Table
            className="adblockreport__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(adblockReportStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
            scroll={{ x: 3300, y: '42vh' }}
            tableLayout="auto"
            size="small"
        />
    );
};

export default AdblockReportTable;
