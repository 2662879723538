enum UserIP {
    WithoutUserIP = 'No',
    WithUserIP = 'Yes',
}

export const UserIPMap: Record<string, string> = {
    0: UserIP.WithoutUserIP,
    1: UserIP.WithUserIP,
};

export type {
    UserIP,
};
