import { DeviceAction, DeviceActionTypes, DeviceState } from '../types/device';

const defaultState: DeviceState = {
    devices: [],
    loading: false,
    error: null,
};

const bundleReducer = (state = defaultState, action: DeviceAction): DeviceState => {
    switch (action.type) {
        case DeviceActionTypes.FETCH_DEVICES_REQUEST:
            return { ...state, loading: true, error: null };
        case DeviceActionTypes.FETCH_DEVICES_SUCCESS:
            return { ...state, devices: action.payload, loading: false, error: null };
        case DeviceActionTypes.FETCH_DEVICES_ERROR:
            return { ...state, error: action.payload, loading: false };
        case DeviceActionTypes.ADD_DEVICE_REQUEST:
            return { ...state, loading: true, error: null };
        case DeviceActionTypes.ADD_DEVICE_SUCCESS:
            return { ...state, devices: [...state.devices, action.payload], loading: false, error: null };
        case DeviceActionTypes.ADD_DEVICE_ERROR:
            return { ...state, error: action.payload, loading: false };
        case DeviceActionTypes.UPDATE_DEVICE_REQUEST:
            return { ...state, loading: true, error: null };
        case DeviceActionTypes.UPDATE_DEVICE_SUCCESS: {
            const updateIdx = state.devices.findIndex(device => device.id === action.payload.id);
            const devices = [...state.devices];
            devices[updateIdx] = action.payload;
            return { ...state, devices, loading: false, error: null };
        }
        case DeviceActionTypes.UPDATE_DEVICE_ERROR:
            return { ...state, error: action.payload, loading: false };
        case DeviceActionTypes.DELETE_DEVICE_REQUEST:
            return { ...state, loading: true, error: null };
        case DeviceActionTypes.DELETE_DEVICE_SUCCESS: {
            const devices = state.devices.filter(device => device.id?.toString() !== action.payload);
            return { ...state, devices, loading: false, error: null };
        }
        case DeviceActionTypes.DELETE_DEVICE_ERROR:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export default bundleReducer;
