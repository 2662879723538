import { AxiosError } from 'axios';
import queryString from 'query-string';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';
import { LangRatioReportAction, LangRatioReportActionTypes } from '../types/langRatioReport';
import { FetchLangRatioReportRequest, LangRatioReportStats } from '../../models/langRatioReport';

// eslint-disable-next-line import/prefer-default-export
export const fetchLangRatioReport = (req: FetchLangRatioReportRequest): Action<LangRatioReportAction> => {
    const {
        from,
        to,
        title,
        provider,
        country,
        rq_language,
        rp_language,
        rp_detected_language,
        groupBy,
    } = req;
    const query = queryString.stringify(
        {
            from,
            to,
            title,
            provider,
            country,
            rq_language,
            rp_language,
            rp_detected_language,
            group_by: groupBy,
        },
        { arrayFormat: 'comma' },
    );

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<LangRatioReportStats>(`reports/languages?${query}`);
            dispatch({ type: LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                LangRatioReportActionTypes.FETCH_LANG_RATIO_REPORT_ERROR,
                'Error fetching language ratio report',
            );
        }
    };
};
