import { useState } from 'react';
import { Space, Button, Popconfirm, notification } from 'antd';
import { DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import Bundle from '../../models/bundle';
import EditBundle from '../modals/EditBundle';
import { DefaultPrice } from '../../models/price';
import { Country } from '../../models/country';
import { UpdateAction, DeleteAction } from '../../store/types/actions';
import { defaultNotify, errorNotify } from '../common/Notify';

interface BundlesActionsProps {
    bundle: Bundle;
    defaultPrice: DefaultPrice;
    countries: Country[];
    error: null | string;
    updateBundle: UpdateAction<Bundle, void>;
    deleteBundle: DeleteAction;
}

const BundlesActions: React.FC<BundlesActionsProps> = props => {
    const { bundle, defaultPrice, countries, error, updateBundle, deleteBundle } = props;
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const handleDelete = async (id: number, appName: string) => {
        try {
            await deleteBundle(id);
            defaultNotify(`${appName} bundle deleted`, '');
        } catch (e: any) {
            errorNotify('Error deleting bundle', e.message || e);
        }
    };

    return (
        <Space size="small">
            <Popconfirm
                placement="topRight"
                title="Are you sure to delete this bundle?"
                onConfirm={() => handleDelete(bundle.id!, bundle.name)}
                okText="Yes"
                cancelText="No"
            >
                <Button size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
            <Button size="small" icon={<EditOutlined />} onClick={() => setShowUpdateModal(true)} />
            <EditBundle
                bundle={bundle}
                defaultPrice={defaultPrice}
                countries={countries}
                open={showUpdateModal}
                setOpen={setShowUpdateModal}
                updateBundle={updateBundle}
                error={error}
            />
        </Space>
    );
};

export default BundlesActions;
