import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getCountryFullName } from '../../models/country';
import { withKeys } from '../../helpers/array';
import { compareDates, formatYearMonthDay } from '../../helpers/date';
import { compareValues } from '../../helpers/compare';
import { LangRatioReportStat } from '../../models/langRatioReport';
import { getProviderDisplayName } from '../../helpers/provider';

interface langRatioReportColumn {
    key: string;
    date: string;
    title: string;
    provider: string;
    country: string;
    rq_language: string;
    rq_wlang: string;
    rp_language: string;
    rp_detected_language: string;
    invalid_rq_to_rp_amount: number;
    invalid_rp_to_detected_amount: number;
    invalid_rq_to_detected_amount: number;
    total: number;
}

interface LanguageRatioReportTableProps {
    loading: boolean;
    error: null | string;
    langRatioReportStats: LangRatioReportStat[];
    groupByList: string[];
}

const LanguageRatioReportTable: React.FC<LanguageRatioReportTableProps> = props => {
    const { loading, langRatioReportStats, groupByList } = props;
    const defaultPageSize = 30;

    const desc = {
        invalidLangRatio: {
            title: `Percentage of responses where the request 
            language does not match the response language`,
        },
        invalidRespDetectedLangRatio: {
            title: `Percentage of responses where the detected 
            response language does not match the response language`,
        },
        invalidReqDetectedLangRatio: {
            title: `Percentage of responses where the detected 
            response language does not match the request language`,
        },
    };

    const getColumns = (): ColumnsType<langRatioReportColumn> => {
        const groupColumns: ColumnsType<langRatioReportColumn> = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'rp_title',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => a.title.toString().localeCompare(b.title.toString()),
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.provider, b.provider),
                render: provider => getProviderDisplayName(provider),
            },
            {
                title: 'Country Name',
                dataIndex: 'country',
                key: 'country',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.country, b.country),
                render: (country: string) => getCountryFullName(country),
            },
            {
                title: 'Request language',
                dataIndex: 'rq_language',
                key: 'rq_language',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.rq_language, b.rq_language),
            },
            {
                title: 'Request Wlanguage',
                dataIndex: 'rq_wlang',
                key: 'rq_wlang',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.rq_wlang, b.rq_wlang),
            },
            {
                title: 'Response language',
                dataIndex: 'rp_language',
                key: 'rp_language',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.rp_language, b.rp_language),
            },
            {
                title: 'Response detected language',
                dataIndex: 'rp_detected_language',
                key: 'rp_detected_language',
                align: 'left',
                fixed: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.rp_detected_language, b.rp_detected_language),
            },
        ];
        const filtered = groupByList.map(item => groupColumns.find(col => col.key!.toString() === item)!);
        return [
            ...filtered,
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                align: 'right',
                width: '10%',
                sorter: (a, b) => compareDates(a.date || '', b.date || ''),
                render: date => <span style={{ whiteSpace: 'nowrap' }}>{formatYearMonthDay(date)}</span>,
            },
            {
                title: 'Invalid language ratio, %',
                dataIndex: 'invalid_rq_to_rp_amount',
                key: 'invalid_rq_to_rp_amount',
                align: 'right',
                width: '20%',
                showSorterTooltip: desc.invalidLangRatio,
                sorter: (a, b) => compareValues(
                    ((a.invalid_rq_to_rp_amount * 100) / a.total),
                    ((b.invalid_rq_to_rp_amount * 100) / b.total),
                ),
                render: (amount, { total }) => ((amount * 100) / total).toFixed(2),
            },
            {
                title: 'Invalid response detected language ratio, %',
                dataIndex: 'invalid_rp_to_detected_amount',
                key: 'invalid_rp_to_detected_amount',
                align: 'right',
                width: '20%',
                showSorterTooltip: desc.invalidRespDetectedLangRatio,
                sorter: (a, b) => compareValues(
                    ((a.invalid_rp_to_detected_amount * 100) / a.total),
                    ((b.invalid_rp_to_detected_amount * 100) / b.total),
                ),
                render: (amount, { total }) => ((amount * 100) / total).toFixed(),
            },
            {
                title: 'Invalid request detected language ratio, %',
                dataIndex: 'invalid_rq_to_detected_amount',
                key: 'invalid_rq_to_detected_amount',
                align: 'right',
                width: '20%',
                showSorterTooltip: desc.invalidReqDetectedLangRatio,
                sorter: (a, b) => compareValues(
                    ((a.invalid_rq_to_detected_amount * 100) / a.total),
                    ((b.invalid_rq_to_detected_amount * 100) / b.total),
                ),
                render: (amount, { total }) => ((amount * 100) / total).toFixed(),
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                width: '10%',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.total - b.total,
                render: total => total.toLocaleString(),
            },
        ];
    };

    return (
        <Table
            id="langratioreporttable"
            className="lang-ratio-report__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(langRatioReportStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
            scroll={{ x: 'max-content', y: '60vh' }}
            tableLayout="auto"
            size="small"
        />
    );
};

export default LanguageRatioReportTable;
