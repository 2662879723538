import { useState } from 'react';
import { Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Country } from '../../models/country';
import { UpdateAction, DeleteAction } from '../../store/types/actions';
import { ProviderListItem } from '../../models/provider';
import { defaultNotify } from '../common/Notify';
import Device from '../../models/device';
import EditDevice from '../modals/EditDevice';

interface DevicesActionsProps {
    device: Device;
    countries: Country[];
    error: null | string;
    providersList: ProviderListItem[];
    updateDevice: UpdateAction<Device, void>;
    deleteDevice: DeleteAction;
}

const DevicesActions: React.FC<DevicesActionsProps> = props => {
    const { device, countries, error, providersList, updateDevice, deleteDevice } = props;
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const handleDelete = () => {
        const { id, device_id: deviceID } = device;
        deleteDevice(id);
        defaultNotify(`${deviceID} device deleted`, '');
    };

    return (
        <Space size="small">
            <EditDevice
                device={device}
                countries={countries}
                open={showUpdateModal}
                setOpen={setShowUpdateModal}
                providersList={providersList}
                updateDevice={updateDevice}
                error={error}
            />
            <Button shape="circle" icon={<EditOutlined />} onClick={() => setShowUpdateModal(true)} />
            <Popconfirm
                placement="topRight"
                title="Are you sure you want to delete this device?"
                onConfirm={() => handleDelete()}
                okText="Yes"
                cancelText="No"
            >
                <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
        </Space>
    );
};

export default DevicesActions;
