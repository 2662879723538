import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName } from '../../models/country';
import { ProviderListItem, ProviderName } from '../../models/provider';
import { isAdmin, isBiddingWriter, UserGroups } from '../../models/user';
import { DeleteAction, UpdateAction } from '../../store/types/actions';
import { compareDates, formatDateTime } from '../../helpers/date';
import { BundleWeight } from '../../models/bundle';
import WeightMapPerBundleActions from './WeightMapPerBundleActions';

interface weightMapPerBundleColumn {
    id?: string;
    bundle: string;
    country: string;
    updated_at: string;
    updated_by: string;
}

interface WeightMapPerBundleTableProps {
    loading: boolean;
    error: null | string;
    weights: BundleWeight[];
    countries: Country[];
    providersList: ProviderListItem[];
    groups: UserGroups[];
    updateBundleWeight: UpdateAction<BundleWeight, void>;
    deleteBundleWeight: DeleteAction;
}

const WeightMapPerBundleTable: React.FC<WeightMapPerBundleTableProps> = props => {
    const { loading, error, weights, countries, providersList, groups, updateBundleWeight, deleteBundleWeight } = props;

    const getWeightMap = (): weightMapPerBundleColumn[] => (
        weights.map(weight => {
            const providerWeights = weight.weights;
            const { id, updated_by, country, bundle } = weight;
            return {
                id: id!.toString(),
                country: getCountryFullName(country),
                bundle,
                updated_at: weight.updated_at || '',
                updated_by,
                ...providerWeights,
            };
        })
    );

    const getProvidersColumns = (): ColumnsType<weightMapPerBundleColumn> => (
        providersList.filter(provider => provider.provider_name !== 'unknown' as ProviderName)
            .map(({ provider_name: providerName, display_name: displayName }) => ({
                title: displayName,
                dataIndex: providerName,
                key: providerName,
                align: 'right',
                render: percent => percent ? `${percent}%` : '',
            }
            ))
    );

    const isAllowedUser = (): boolean => isAdmin(groups) ? true : isBiddingWriter(groups);

    const renderActions = (record: any) => {
        const weight = weights.find(w => w.id === +record.id);
        return isAllowedUser() && weight
            ? (
                <WeightMapPerBundleActions
                    weight={weight}
                    error={error}
                    countries={countries}
                    providersList={providersList}
                    updateBundleWeight={updateBundleWeight}
                    deleteBundleWeight={deleteBundleWeight}
                />
            ) : null;
    };

    const columns: ColumnsType<weightMapPerBundleColumn> = [
        {
            title: 'Bundle Name',
            dataIndex: 'bundle',
            key: 'bundle',
            width: '20%',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.bundle.localeCompare(b.bundle),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Country Name',
            dataIndex: 'country',
            key: 'country',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.country.localeCompare(b.country),
            defaultSortOrder: 'ascend',
        },
        ...getProvidersColumns(),
        {
            title: 'Updated at',
            dataIndex: 'updated_at',
            key: 'updatedAt',
            align: 'right',
            width: '12%',
            render: updatedAt => <span>{formatDateTime(updatedAt)}</span>,
            sorter: (a, b) => compareDates(a.updated_at || '', b.updated_at || ''),
        },
        {
            title: 'Updated by',
            dataIndex: 'updated_by',
            key: 'updatedBy',
            align: 'right',
            width: '12%',
            sorter: (a, b) => a.updated_by.localeCompare(b.updated_by),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '4%',
            render: renderActions,
        },
    ];

    const getScrollX = () => 50 * providersList.length + 1350;

    return (
        <Table
            className="weightmap-per-bundle__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.id!}
            dataSource={getWeightMap()}
            columns={columns}
            size="small"
            pagination={false}
            scroll={{ x: getScrollX(), y: '69vh' }}
        />
    );
};

export default WeightMapPerBundleTable;
