import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { TemplateTypeMap } from '../../models/template';

const { Option } = Select;

interface TemplateTypeSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const TemplateTypeSelect: React.FC<TemplateTypeSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onTemplateTypeSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 120 }}
            optionFilterProp="children"
            placeholder="Select template type"
            onSelect={onTemplateTypeSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.entries(TemplateTypeMap)].map(item => <Option key={item[0]} value={item[0]}>{item[1]}</Option>)}
        </Select>
    );
};

export default TemplateTypeSelect;
